import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { openReferralModal } from '../../actions/modalActions';
import { backendUrl } from '../../config';
import messages from '../../locale/messages';

import s from './SocialLogin.module.css';

// import ReferralModal from '../../components/ReferralModal';

class SocialLogin extends Component {
  static propTypes = {
    formatMessage: PropTypes.func,
    refer: PropTypes.string,
    signUpMsg: PropTypes.bool,
    openReferralModal: PropTypes.func,
    referralKey: PropTypes.string,
  };

  render() {
    const {
      refer,
      signUpMsg,
      loginKey,
      openReferralModal,
      referralKey,
    } = this.props;
    let FbURL = '/login/facebook';
    let GoogleURL = '/login/google';
    let LinkedinURL = `${backendUrl}/login/linkedin`;
    if (refer) {
      FbURL = `/login/facebook?refer=${refer}`;
      GoogleURL = `/login/google?refer=${refer}`;
      LinkedinURL = `${backendUrl}/login/linkedin?refer=${refer}`;
    }
    if (loginKey) {
      FbURL = `/login/facebook?ref=${loginKey}`;
      GoogleURL = `/login/google?ref=${loginKey}`;
      LinkedinURL = `${backendUrl}/login/linkedin?ref=${loginKey}`;
    }
    if (referralKey) {
      FbURL = `/login/facebook?ref=${referralKey}`;
      GoogleURL = `/login/google?ref=${referralKey}`;
      LinkedinURL = `${backendUrl}/login/linkedin?ref=${referralKey}`;
    }
    if (!signUpMsg) {
      const LinkedinURL = `${backendUrl}/login/linkedin`;
    }

    return (
      <div>
        {/* <div className={s.formGroup}>

          <a className={s.facebook} href={FbURL}>
            <svg
              className={s.icon}
              width="30"
              height="30"
              viewBox="0 0 30 30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 16l1-5h-5V7c0-1.544.784-2 3-2h2V0h-4c-4.072 0-7 2.435-7 7v4H7v5h5v14h6V16h4z"
              />
            </svg>
            <FormattedMessage {...messages.facebookLogin} />
          </a>
        </div>

        <div className={s.formGroup}>
          <a className={s.google} href={GoogleURL}>
            <svg
              className={s.icon}
              width="30"
              height="30"
              viewBox="0 0 30 30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d={'M30 13h-4V9h-2v4h-4v2h4v4h2v-4h4m-15 2s-2-1.15-2-2c0 0-.5-1.828 1-3 ' +
                  '1.537-1.2 3-3.035 3-5 0-2.336-1.046-5-3-6h3l2.387-1H10C5.835 0 2 3.345 2 7c0 ' +
                  '3.735 2.85 6.56 7.086 6.56.295 0 .58-.006.86-.025-.273.526-.47 1.12-.47 1.735 ' +
                  '0 1.037.817 2.042 1.523 2.73H9c-5.16 0-9 2.593-9 6 0 3.355 4.87 6 10.03 6 5.882 ' +
                  '0 9.97-3 9.97-7 0-2.69-2.545-4.264-5-6zm-4-4c-2.395 0-5.587-2.857-6-6C4.587 ' +
                  '3.856 6.607.93 9 1c2.394.07 4.603 2.908 5.017 6.052C14.43 10.195 13 13 11 ' +
                  '13zm-1 15c-3.566 0-7-1.29-7-4 0-2.658 3.434-5.038 7-5 .832.01 2 0 2 0 1 0 ' +
                  '2.88.88 4 2 1 1 1 2.674 1 3 0 3-1.986 4-7 4z'}
              />
            </svg>
            <FormattedMessage {...messages.googleLogin} />
          </a>
        </div> */}
        <div className={s.formGroup}>
          {/* <ReferralModal /> */}
          {!signUpMsg && (
            <a className={s.linkedin} href={LinkedinURL}>
              <svg
                className={s.icon}
                height="30px"
                viewBox="0 0 382 382"
                width="30px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889  C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056  H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806  c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1  s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73  c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079  c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426  c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472  L341.91,330.654L341.91,330.654z" />
              </svg>
              {!signUpMsg && <FormattedMessage {...messages.linkedinLogin} />}
            </a>
          )}
          {signUpMsg && referralKey && (
            <a className={s.linkedin} href={LinkedinURL}>
              <svg
                className={s.icon}
                height="30px"
                viewBox="0 0 382 382"
                width="30px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889  C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056  H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806  c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1  s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73  c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079  c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426  c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472  L341.91,330.654L341.91,330.654z" />
              </svg>
              {/* {!signUpMsg && <FormattedMessage {...messages.linkedinLogin} />} */}
              {signUpMsg && (
                <FormattedMessage {...messages.signupWithLinkedin} />
              )}
            </a>
          )}
          {signUpMsg && !referralKey && !loginKey && (
            <a className={s.linkedin} onClick={openReferralModal}>
              <svg
                className={s.icon}
                height="30px"
                viewBox="0 0 382 382"
                width="30px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889  C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056  H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806  c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1  s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73  c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079  c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426  c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472  L341.91,330.654L341.91,330.654z" />
              </svg>
              {/* {!signUpMsg && <FormattedMessage {...messages.linkedinLogin} />} */}
              {signUpMsg && (
                <FormattedMessage {...messages.signupWithLinkedin} />
              )}
            </a>
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  referralKey: state.referral.referralKeyValue,
});

const mapDispatch = {
  openReferralModal,
};

export default injectIntl(connect(mapState, mapDispatch)(SocialLogin));
// export default (SocialLogin);
