import React from 'react';

import cx from 'classnames';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { Col, Grid, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { openSubscriptionModal } from '../../actions/VerificationModal/verifyModal';
import facebookIcon from '../../assets/facebook.svg';
import InstaIcon from '../../assets/instagram.svg';
import LinkedIcon from '../../assets/linkedin.svg';
import TwitterIcon from '../../assets/twitter.svg';
import { backendUrl, sitename } from '../../config';
import fetch from '../../core/fetch';
import messages from '../../locale/messages';
import CurrencySwitcher from '../CurrencySwitcher';
import LanguageSwitcher from '../LanguageSwitcher';
import Link from '../Link';
import SubscriptionModal from '../SubscriptionModal';

import s from './Footer.module.css';
import footerIcon from './footerLogo.png';

const getEnabledBlog = loader('./getEnabledBlog.graphql');

class Footer extends React.Component {
  static propTypes = {
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
    formatMessage: PropTypes.func,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      getEnabledBlog: PropTypes.array,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      golightly: false,
      hosting: false,
      discover: false,
      newFeatures: false,
    };
    this.handleOpenRules = this.handleOpenRules.bind(this);
    this.subscription = this.subscription.bind(this);
  }

  componentDidMount() {
    const {
      data: { getEnabledBlog },
    } = this.props;
    getEnabledBlog &&
      getEnabledBlog.length > 0 &&
      getEnabledBlog.map((item, key) => {
        if (item.footerCategory === 'Golightly') {
          this.setState({ golightly: true });
        }
        if (item.footerCategory === 'discover') {
          this.setState({ discover: true });
        }
        if (item.footerCategory === 'policies') {
          this.setState({ hosting: true });
        }
        if (item.footerCategory === 'newfeatures') {
          this.setState({ newFeatures: true });
        }
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      data: { getEnabledBlog },
    } = nextProps;
    getEnabledBlog &&
      getEnabledBlog.length > 0 &&
      getEnabledBlog.map((item, key) => {
        if (item.footerCategory === 'Golightly') {
          this.setState({ golightly: true });
        }
        if (item.footerCategory === 'discover') {
          this.setState({ discover: true });
        }
        if (item.footerCategory === 'policies') {
          this.setState({ hosting: true });
        }
        if (item.footerCategory === 'newfeatures') {
          this.setState({ newFeatures: true });
        }
      });
  }

  subscription() {
    const { openSubscriptionModal } = this.props;
    openSubscriptionModal();
  }

  async handleOpenRules() {
    const { account } = this.props;
    const email = account && account.email;
    const firstName = account && account.firstName;
    const lastName = account && account.lastName;

    toastr.success('Subscribed!');

    const { status, errorMessage } = await fetch(
      `${backendUrl}/getMailChimpSubscribe`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
        credentials: 'include',
      }
    );

    let resp1;
    if (status === 404) {
      resp1 = await fetch(`${backendUrl}/mailChimpSubscribe-footer`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
        }),
        credentials: 'include',
      });
    }
    if (status === 200 || (resp1 && resp1.status === 200)) {
      const resp2 = await fetch(`${backendUrl}/mailChimpUpdateProfile-footer`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
        }),
        credentials: 'include',
      });
    }
  }

  render() {
    const { golightly, discover, hosting, newFeatures } = this.state;
    const {
      data: { loading, getEnabledBlog },
      account,
      isAuthenticated,
    } = this.props;
    const currentYear = new Date().getFullYear();
    let disabled = false;
    if (account && account.userId === 'c505e590-f3bc-11e9-bdc7-0913579b3abc') {
      disabled = true;
    }

    const isDiamond = account && account.isDiamond;

    return (
      <div className={s.root}>
        <div className={s.container}>
          <div className={cx(s.footerSectionContainer, 'hidden-print')}>
            <SubscriptionModal />
            <Grid fluid>
              <Row className={s.reverseFlex}>
                <Col
                  className={cx(s.dropDownSection)}
                  lg={2}
                  lgHidden
                  md={2}
                  mdHidden
                  sm={3}
                  smHidden
                  xs={12}
                >
                  <div className={s.currencyText}>
                    <FormattedMessage {...messages.footerLabel} />
                  </div>
                  <CurrencySwitcher />
                  <LanguageSwitcher />
                </Col>
                <Col className={cx(s.noPadding)} lg={2} md={2} sm={3} xs={6}>
                  <label className={s.landingLabel}>{sitename}</label>
                  <ul className={s.listContainer}>
                    <li>
                      <Link className={s.textLink} to="/about-us">
                        <FormattedMessage {...messages.about} />
                      </Link>
                    </li>
                    <li>
                      <Link className={s.textLink} to="/contactUs">
                        <FormattedMessage {...messages.contactForm} />
                      </Link>
                    </li>
                    {!account && (
                      <li>
                        <Link className={s.textLink} to="/become-a-member">
                          <FormattedMessage {...messages.becomeAMember} />
                        </Link>
                      </li>
                    )}
                    {account && !disabled && (
                      <li>
                        <Link className={s.textLink} to="/become-a-member">
                          <FormattedMessage {...messages.referFriends} />
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link className={s.textLink} to="/howtohost">
                        <FormattedMessage {...messages.howToHost} />
                      </Link>
                    </li>
                    <li>
                      <Link className={s.textLink} to="/help">
                        <FormattedMessage {...messages.help} />
                      </Link>
                    </li>
                    {account &&
                      golightly &&
                      getEnabledBlog &&
                      getEnabledBlog.length > 0 &&
                      getEnabledBlog.map((item, key) => {
                        if (
                          item.footerCategory === 'Golightly' &&
                          (item.access === 'public' ||
                            item.access === 'private')
                        ) {
                          return (
                            <li>
                              <Link
                                className={s.textLink}
                                to={`/page/${item.pageUrl}`}
                              >
                                {item.pageTitle}
                              </Link>
                            </li>
                          );
                        }
                      })}

                    {!account &&
                      golightly &&
                      getEnabledBlog &&
                      getEnabledBlog.length > 0 &&
                      getEnabledBlog.map((item, key) => {
                        if (
                          item.footerCategory === 'Golightly' &&
                          item.access === 'public'
                        ) {
                          return (
                            <li>
                              <Link
                                className={s.textLink}
                                to={`/page/${item.pageUrl}`}
                              >
                                {item.pageTitle}
                              </Link>
                            </li>
                          );
                        }
                      })}
                  </ul>
                </Col>

                <Col
                  className={cx(s.noPadding, s.mobileHeight)}
                  lg={2}
                  md={2}
                  sm={3}
                  xs={6}
                >
                  <label className={s.landingLabel}>
                    <FormattedMessage {...messages.stayCloseTo} />
                  </label>
                  <ul className={cx(s.listContainer)}>
                    <li>
                      <Link className={s.textLink} to="/cities/texas">
                        <FormattedMessage {...messages.austinDiscover} />
                      </Link>
                    </li>
                    <li>
                      <Link className={s.textLink} to="/cities/new-england">
                        <FormattedMessage {...messages.newYorkText} />
                      </Link>
                    </li>
                    <li>
                      <Link className={s.textLink} to="/cities/florida">
                        <FormattedMessage {...messages.miamiDiscover} />
                      </Link>
                    </li>
                    <li>
                      <Link className={s.textLink} to="/cities/uk">
                        <FormattedMessage {...messages.londonDiscover} />
                      </Link>
                    </li>
                    <li>
                      <Link className={s.textLink} to="/cities/california">
                        <FormattedMessage {...messages.sanFansicoDiscover} />
                      </Link>
                    </li>
                    <li>
                      <Link className={s.textLink} to="/cities/colorado">
                        <FormattedMessage {...messages.chicagoDiscover} />
                      </Link>
                    </li>
                    <li>
                      <Link className={s.textLink} to="/cities/europe">
                        <FormattedMessage {...messages.italyDiscover} />
                      </Link>
                    </li>
                    <li>
                      <Link className={s.textLink} to="/exploreMore">
                        <FormattedMessage {...messages.exploreMoreText} />
                      </Link>
                    </li>
                    {account &&
                      discover &&
                      getEnabledBlog &&
                      getEnabledBlog.length > 0 &&
                      getEnabledBlog.map((item, key) => {
                        if (
                          item.footerCategory === 'discover' &&
                          (item.access === 'public' ||
                            item.access === 'private')
                        ) {
                          return (
                            <li>
                              <Link
                                className={s.textLink}
                                to={`/page/${item.pageUrl}`}
                              >
                                {item.pageTitle}
                              </Link>
                            </li>
                          );
                        }
                      })}
                    {!account &&
                      discover &&
                      getEnabledBlog &&
                      getEnabledBlog.length > 0 &&
                      getEnabledBlog.map((item, key) => {
                        if (
                          item.footerCategory === 'discover' &&
                          item.access === 'public'
                        ) {
                          return (
                            <li>
                              <Link
                                className={s.textLink}
                                to={`/page/${item.pageUrl}`}
                              >
                                {item.pageTitle}
                              </Link>
                            </li>
                          );
                        }
                      })}
                  </ul>
                </Col>

                <Col className={cx(s.noPadding)} lg={2} md={2} sm={3} xs={6}>
                  <label className={s.landingLabel}>
                    <FormattedMessage {...messages.policies} />
                  </label>
                  <ul className={s.listContainer}>
                    <li>
                      <Link className={s.textLink} to="/travelOftenGolightly">
                        <FormattedMessage {...messages.travelOften} />
                      </Link>
                    </li>
                    <li>
                      <Link className={s.textLink} to="/travel">
                        <FormattedMessage {...messages.travelCredit} />
                      </Link>
                    </li>
                    <li>
                      <Link className={s.textLink} to="/safety">
                        <FormattedMessage {...messages.trustSafety} />
                      </Link>
                    </li>
                    <li>
                      <Link className={cx(s.textLink)} to="/termsService">
                        <FormattedMessage {...messages.termsService} />
                      </Link>
                    </li>
                    <li>
                      <Link className={cx(s.textLink)} to="/privacy">
                        <FormattedMessage {...messages.termsPrivacy} />
                      </Link>
                    </li>
                    {account &&
                      hosting &&
                      getEnabledBlog &&
                      getEnabledBlog.length > 0 &&
                      getEnabledBlog.map((item, key) => {
                        if (
                          item.footerCategory === 'policies' &&
                          (item.access === 'public' ||
                            item.access === 'private')
                        ) {
                          return (
                            <li>
                              <Link
                                className={s.textLink}
                                to={`/page/${item.pageUrl}`}
                              >
                                {item.pageTitle}
                              </Link>
                            </li>
                          );
                        }
                      })}
                    {!account &&
                      hosting &&
                      getEnabledBlog &&
                      getEnabledBlog.length > 0 &&
                      getEnabledBlog.map((item, key) => {
                        if (
                          item.footerCategory === 'policies' &&
                          item.access === 'public'
                        ) {
                          return (
                            <li>
                              <Link
                                className={s.textLink}
                                to={`/page/${item.pageUrl}`}
                              >
                                {item.pageTitle}
                              </Link>
                            </li>
                          );
                        }
                      })}
                  </ul>
                </Col>
                <Col className={cx(s.noPadding)} lg={2} md={2} sm={3} xs={6}>
                  <label className={s.landingLabel}>
                    <FormattedMessage {...messages.newFeatures} />
                  </label>
                  <ul className={s.listContainer}>
                    {isDiamond && (
                      <li>
                        <Link className={s.textLink} to="/concierge">
                          <FormattedMessage {...messages.concierge} />
                        </Link>
                      </li>
                    )}
                    {/* {
                      !account && newFeatures && getEnabledBlog && getEnabledBlog.length > 0 && getEnabledBlog.map((item, key) => {
                        if (item.footerCategory === 'newfeatures' && item.id === 7) {
                          return (
                            <li>
                              <Link to={'/page/' + item.pageUrl} className={s.textLink} >
                                {item.pageTitle}
                                <sup className={s.newSup}><FormattedMessage {...messages.messagesNew} /></sup>
                              </Link>
                            </li>
                          )
                        }
                      })
                    } */}
                    {/* {!account && (
                      <li>
                        <Link className={s.textLink} to="/concierge">
                          <FormattedMessage {...messages.aboutConcierge} />
                        </Link>
                      </li>
                    )} */}
                    {newFeatures &&
                      getEnabledBlog &&
                      getEnabledBlog.length > 0 &&
                      getEnabledBlog.map((item, key) => {
                        if (
                          item.footerCategory === 'newfeatures' &&
                          item.id !== 7
                        ) {
                          if (
                            account &&
                            (item.access === 'public' ||
                              item.access === 'private')
                          ) {
                            return (
                              <li>
                                <Link
                                  className={s.textLink}
                                  to={`/page/${item.pageUrl}`}
                                >
                                  {item.pageTitle}
                                </Link>
                              </li>
                            );
                          }
                          if (
                            !account &&
                            item.access === 'public' &&
                            item.id !== 7
                          ) {
                            return (
                              <li>
                                <Link
                                  className={s.textLink}
                                  to={`/page/${item.pageUrl}`}
                                >
                                  {item.pageTitle}
                                </Link>
                              </li>
                            );
                          }
                        }
                      })}
                    <li>
                      <Link className={s.textLink} to="/member-perks">
                        <FormattedMessage {...messages.memberPerks} />
                        {/* <sup className={s.newSup}>
                          <FormattedMessage {...messages.messagesNew} />
                        </sup> */}
                      </Link>
                    </li>
                    {account && (
                      <li>
                        <Link className={s.textLink} to="/go-host">
                          <FormattedMessage {...messages.goHost} />
                          {/* <sup className={s.newSup}>
                            <FormattedMessage {...messages.messagesNew} />
                          </sup> */}
                        </Link>
                      </li>
                    )}
                    {isDiamond && (
                      <li>
                        <Link className={s.textLink} to="/diamond-member">
                          <FormattedMessage {...messages.diamondMemberLabel} />
                        </Link>
                      </li>
                    )}
                  </ul>
                </Col>
                <Col
                  className={cx(s.dropDownSection)}
                  lg={3}
                  md={4}
                  sm={12}
                  xs={12}
                  xsHidden
                >
                  <div className={s.btnSection}>
                    <div className={s.currencyText}>
                      <FormattedMessage {...messages.footerLabel} />
                    </div>
                    <div>
                      <Col
                        className={cx(s.paddingLeftNone, s.paddingNone)}
                        lg={12}
                        md={12}
                        sm={6}
                        xs={12}
                      >
                        <CurrencySwitcher />
                      </Col>
                      <Col
                        className={cx(s.paddingRightNone, s.paddingNone)}
                        lg={12}
                        md={12}
                        sm={6}
                        xs={12}
                      >
                        <LanguageSwitcher />
                      </Col>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className={s.copyrightSection}>
                <hr className={s.horizontalLineThrough} />

                <Col className={s.noPadding} lg={6} md={6} sm={8} xs={12}>
                  {/* <span className={s.footerLogo}><img src={footerIcon}/></span><span className={cx(s.text, s.fontWeight)}>© {currentYear} {' '} <FormattedMessage {...messages.copyRightText} /></span> */}
                  <span className={s.footerLogo}>
                    <img src={footerIcon} />
                  </span>
                  <span className={cx(s.text, s.fontWeight)}>
                    © {currentYear}{' '}
                    <FormattedMessage {...messages.copyRightText} />
                  </span>
                  <a
                    className={s.socialImgLink}
                    href="https://www.facebook.com/WeGolightly/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img className={s.socialImg} src={facebookIcon} />
                  </a>
                  <a
                    className={s.socialImgLink}
                    href="https://www.instagram.com/wegolightly/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img className={s.socialImg} src={InstaIcon} />
                  </a>
                  <a
                    className={s.socialImgLink}
                    href="https://www.linkedin.com/company/wegolightly"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img className={s.socialImg} src={LinkedIcon} />
                  </a>
                  <a
                    className={s.socialImgLink}
                    href="https://twitter.com/GolightlyTravel"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img className={s.socialImg} src={TwitterIcon} />
                  </a>
                  {/* <a
                    onClick={this.subscription}
                    className={cx(s.pointer,s.socialImgLink)}
                  >
                    <FormattedMessage {...messages.subscriptionLabel} />
                  </a> */}
                  {/* {
                    <a
                      className={cx(s.pointer,s.socialImgLink)}
                      onClick={isAuthenticated ? this.handleOpenRules : this.subscription}
                    >
                      <FormattedMessage {...messages.subscriptionLabel} />
                    </a>
                  } */}
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  // siteName: state.siteSettings.data.siteName,
  facebook: state.siteSettings.data.facebookLink,
  twitter: state.siteSettings.data.twitterLink,
  instagram: state.siteSettings.data.instagramLink,
  account: state.account.data,
  isAuthenticated: state.runtime.isAuthenticated,
});

const mapDispatch = {
  openSubscriptionModal,
};

export default compose(
  connect(mapState, mapDispatch),
  graphql(getEnabledBlog, {
    options: {
      fetchPolicy: 'network-only',
      ssr: false,
    },
  })
)(Footer);
