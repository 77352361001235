import React from 'react';

import moment from 'moment';
import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import CurrencyView from '../modules/CurrencyView';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class NewInquiry extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      receiverName: PropTypes.string.isRequired,
      userType: PropTypes.string.isRequired,
      senderName: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      threadId: PropTypes.number.isRequired,
      checkIn: PropTypes.string.isRequired,
      checkout: PropTypes.string.isRequired,
      personCapacity: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const btnCenter = {
      textAlign: 'center',
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'bold',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const {
      content: { receiverName, type, senderName, message, threadId },
    } = this.props;
    const {
      content: { checkIn, checkOut, personCapacity, status },
    } = this.props;
    const {
      content: {
        hostProfileId,
        guestProfileId,
        currency,
        offerPrice,
        listTitle,
        listId,
      },
    } = this.props;
    const {
      content: {
        isFinalAverage,
        dayDifference,
        finalpriceForDays,
        taxRateFee,
        additionalGuestPrice,
      },
    } = this.props;
    const {
      content: {
        additionalGuest,
        discount,
        serviceFee,
        cleaningPrice,
        total,
        taxRate,
        discountType,
      },
    } = this.props;
    const {
      content: { base, rates, toCurrency },
    } = this.props;

    let messageURL = `${url}/message/${threadId}/guest`;
    if (type === 'host') {
      messageURL = `${url}/message/${threadId}/host`;
    }
    const checkInDate = checkIn
      ? moment(checkIn).format('ddd, MMM Do, YYYY')
      : '';
    const checkOutDate = checkOut
      ? moment(checkOut).format('ddd, MMM Do, YYYY')
      : '';
    const inquiryStatus = status === 'inquiry';
    const profileURL = `${url}/users/show/`;
    const listURL = `${url}/rooms/${listId}`;
    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>
                    Hi <a href={profileURL + hostProfileId}>{receiverName}</a>,
                  </div>
                  <EmptySpace height={20} />
                  <div>
                    {inquiryStatus && (
                      <p>
                        You have 24 hours to accept or decline this offer.
                        Please respond so we can help all our members travel
                        safely.
                      </p>
                    )}
                  </div>
                  <EmptySpace height={20} />
                  <div>
                    {inquiryStatus && <span>You have a new inquiry from</span>}
                    {!inquiryStatus && (
                      <span>You have a special price request from</span>
                    )}{' '}
                    <a href={profileURL + guestProfileId}>{senderName}</a>.
                  </div>
                  <EmptySpace height={20} />
                  <div>Check In: {checkInDate}</div>
                  <EmptySpace height={20} />
                  <div>Check Out: {checkOutDate}</div>
                  <EmptySpace height={20} />
                  <div>Guests: {personCapacity}</div>
                  <EmptySpace height={20} />
                  <div>
                    List Title: <a href={listURL}>{listTitle}</a>
                  </div>
                  {/* {
                    inquiryStatus && <div>
                      <CurrencyConvert
                        amount={isFinalAverage}
                        base={base}
                        rates={rates}
                        toCurrency={toCurrency}
                        from={currency}
                        currency={toCurrency}
                      />{' '}x{' '}{dayDifference}{' '}{dayDifference > 1 ? 'nights' : 'night'}{' '}<CurrencyConvert
                        amount={finalpriceForDays}
                        base={base}
                        rates={rates}
                        toCurrency={toCurrency}
                        from={currency}
                        currency={toCurrency}
                      />
                    </div>
                  }
                  {
                    taxRate > 0 && <div>
                      Taxes{' '}<CurrencyConvert
                        amount={taxRateFee}
                        base={base}
                        rates={rates}
                        toCurrency={toCurrency}
                        from={currency}
                        currency={toCurrency}
                      />
                    </div>
                  }

                  {
                    additionalGuestPrice > 0 && additionalGuest > 0 && inquiryStatus && <div>
                      Extra guests:{' '}{additionalGuest}{' '}{additionalGuest > 1 ? 'guests' : 'guest'}{' '}x{' '}{dayDifference}{' '}{dayDifference > 1 ? 'nights' : 'night'}{' '}<CurrencyConvert
                        amount={additionalGuestPrice}
                        base={base}
                        rates={rates}
                        toCurrency={toCurrency}
                        from={currency}
                        currency={toCurrency}
                      />
                    </div>
                  }
                  {
                    discount > 0 && inquiryStatus && <div>
                      {discountType}{' - '}<CurrencyConvert
                        amount={discount}
                        base={base}
                        rates={rates}
                        toCurrency={toCurrency}
                        from={currency}
                        currency={toCurrency}
                      />
                    </div>
                  }
                  {
                    serviceFee > 0 && inquiryStatus && <div>
                      Service fee{' '}<CurrencyConvert
                        amount={serviceFee}
                        base={base}
                        rates={rates}
                        toCurrency={toCurrency}
                        from={currency}
                        currency={toCurrency}
                      />
                    </div>
                  }
                  {
                    cleaningPrice > 0 && inquiryStatus && <div>
                      Cleaning fee{' '}<CurrencyConvert
                        amount={cleaningPrice}
                        base={base}
                        rates={rates}
                        toCurrency={toCurrency}
                        from={currency}
                        currency={toCurrency}

                      />
                    </div>
                  } */}
                  {inquiryStatus && <EmptySpace height={20} />}
                  {inquiryStatus && (
                    <div>
                      {/* Estimated payout:{' '}<CurrencyConvert
                        amount={total}
                        base={base}
                        rates={rates}
                        toCurrency={toCurrency}
                        from={currency}
                        currency={toCurrency}
                      /> */}
                      Estimated payout:{' '}
                      <CurrencyView amount={total} currency={currency} />
                    </div>
                  )}
                  {!inquiryStatus && <EmptySpace height={20} />}
                  {!inquiryStatus && (
                    <div>
                      Offer Price Per Night:{' '}
                      <CurrencyView amount={offerPrice} currency={currency} />
                    </div>
                  )}

                  <EmptySpace height={20} />
                  <div>Message:</div>
                  <EmptySpace height={10} />
                  <div>{message}</div>
                  <EmptySpace height={40} />
                  <div style={btnCenter}>
                    <a href={messageURL} style={buttonStyle}>
                      Respond to {senderName}
                    </a>
                  </div>
                  <EmptySpace height={40} />
                  <div>Thanks,</div>
                  <EmptySpace height={20} />
                  <div>The {sitename} Team</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default NewInquiry;
