const privileges = [
  {
    id: 36,
    privilege: 'Dashboard',
    permittedUrls: ['/siteadmin'],
  },
  {
    id: 1,
    privilege: 'Manage Site Settings',
    permittedUrls: ['/siteadmin/settings/site'],
  },
  {
    id: 2,
    privilege: 'Manage Users',
    permittedUrls: ['/siteadmin/users', '/siteadmin/profileView/'],
  },
  {
    id: 3,
    privilege: 'Manage Press Users',
    permittedUrls: ['/siteadmin/pressUsers'],
  },
  {
    id: 4,
    privilege: 'Deleted Users',
    permittedUrls: ['/siteadmin/deletedUsers'],
  },
  {
    id: 5,
    privilege: 'Manage Members',
    permittedUrls: ['/siteadmin/members'],
  },
  {
    id: 6,
    privilege: 'Property Management',
    permittedUrls: ['/siteadmin/property-managers', '/reservation/current'],
  },
  {
    id: 7,
    privilege: 'Manage Listings',
    permittedUrls: [
      '/siteadmin/listings',
      '/become-a-host/',
      '/siteadmin/deleted-listings',
    ],
  },
  {
    id: 8,
    privilege: 'Approved Listings',
    permittedUrls: ['/siteadmin/listingapproval'],
  },
  {
    id: 9,
    privilege: 'Manage Top Picks',
    permittedUrls: ['/siteadmin/topPicks'],
  },
  {
    id: 10,
    privilege: 'Manage Long Term Picks',
    permittedUrls: ['/siteadmin/long-term-stay'],
  },
  {
    id: 11,
    privilege: 'Manage Solo Travel Picks',
    permittedUrls: ['/siteadmin/soloPicks'],
  },
  {
    id: 12,
    privilege: 'Manage Unique Travel Picks',
    permittedUrls: ['/siteadmin/uniquePicks'],
  },
  {
    id: 13,
    privilege: 'Manage Family Friendly Picks',
    permittedUrls: ['/siteadmin/familyPicks'],
  },
  {
    id: 14,
    privilege: 'Manage Business Travel Picks',
    permittedUrls: ['/siteadmin/businessPicks'],
  },
  {
    id: 15,
    privilege: 'Manage Reservations',
    permittedUrls: ['/siteadmin/reservations', '/siteadmin/viewreservation/'],
  },
  {
    id: 16,
    privilege: 'iCal Management',
    permittedUrls: ['/siteadmin/ical'],
  },
  {
    id: 17,
    privilege: 'Manage User Reviews',
    permittedUrls: ['/siteadmin/user-reviews'],
  },
  {
    id: 18,
    privilege: 'Manage Admin Reviews',
    permittedUrls: ['/siteadmin/reviews', '/siteadmin/write-reviews'],
  },
  {
    id: 19,
    privilege: 'Manage Site Service Fee',
    permittedUrls: ['/siteadmin/settings/servicefees'],
  },
  {
    id: 20,
    privilege: 'Manage Property Service Fee',
    permittedUrls: ['/siteadmin/settings/property-servicefees'],
  },
  {
    id: 21,
    privilege: 'ID Proof Verification Management',
    permittedUrls: ['/siteadmin/id-proof'],
  },
  {
    id: 22,
    privilege: 'View User Messages',
    permittedUrls: ['/siteadmin/messages', '/message/'],
  },
  {
    id: 23,
    privilege: 'View Private Message',
    permittedUrls: ['/siteadmin/privatemessages', '/message/'],
  },
  {
    id: 24,
    privilege: 'Connection Message',
    permittedUrls: ['/siteadmin/connection'],
  },
  {
    id: 25,
    privilege: 'Manage User Reports',
    permittedUrls: ['/siteadmin/reportUser'],
  },
  {
    id: 26,
    privilege: 'Manage Search Settings',
    permittedUrls: ['/siteadmin/settings/search'],
  },
  {
    id: 27,
    privilege: 'Manage Travel Credit',
    permittedUrls: ['/siteadmin/travelCredit'],
  },
  {
    id: 28,
    privilege: 'Manage Home Page Banners',
    permittedUrls: [
      '/siteadmin/home/caption',
      '/siteadmin/home/banner',
      '/siteadmin/home/footer-block',
      '/siteadmin/home/footer-signup-block',
      '/siteadmin/home/footer-info-block',
      '/siteadmin/home/home-banner',
    ],
  },
  {
    id: 29,
    privilege: 'Manage Listing Settings',
    permittedUrls: [
      '/siteadmin/listsettings/1',
      '/siteadmin/listsettings/2',
      '/siteadmin/listsettings/3',
      '/siteadmin/listsettings/4',
      '/siteadmin/listsettings/5',
      '/siteadmin/listsettings/6',
      '/siteadmin/listsettings/7',
      '/siteadmin/listsettings/8',
      '/siteadmin/listsettings/9',
      '/siteadmin/listsettings/10',
      '/siteadmin/listsettings/11',
      '/siteadmin/listsettings/12',
      '/siteadmin/listsettings/13',
      '/siteadmin/listsettings/14',
      '/siteadmin/listsettings/15',
      '/siteadmin/listsettings/16',
      '/siteadmin/listsettings/18',
      '/siteadmin/listsettings/19',
      '/siteadmin/listsettings/20',
      '/siteadmin/listsettings/21',
      '/siteadmin/listsettings/22',
      '/siteadmin/listsettings/23',
    ],
  },
  {
    id: 30,
    privilege: 'Manage Member Perks',
    permittedUrls: ['/siteadmin/member-perks'],
  },
  {
    id: 31,
    privilege: 'Manage Go Host',
    permittedUrls: ['/siteadmin/go-host'],
  },
  {
    id: 32,
    privilege: 'Manage CMS Pages',
    permittedUrls: [
      '/siteadmin/content-management',
      '/siteadmin/page/add',
      '/siteadmin/staticpage/management',
      '/siteadmin/edit/staticpage/',
    ],
  },
  {
    id: 33,
    privilege: 'Blog Content Management',
    permittedUrls: [
      '/siteadmin/blog-content-management',
      '/siteadmin/page/add/content',
      '/siteadmin/page/edit/page/content',
      '/siteadmin/social',
      '/siteadmin/event/add',
      '/siteadmin/event/edit/',
      '/siteadmin/user-blog-content-management',
      '/siteadmin/event',
      '/siteadmin/tag-management',
    ],
  },
  {
    id: 34,
    privilege: 'Main Host Management',
    permittedUrls: [
      '/siteadmin/mainstaticpage/management/1',
      '/siteadmin/mainstaticpage/management/2',
    ],
  },
  {
    id: 35,
    privilege: 'Natural Retreats',
    permittedUrls: [
      '/siteadmin/naturalretreats/all',
      '/siteadmin/naturalretreats/account',
      '/siteadmin/naturalretreats/logs',
      '/siteadmin/naturalretreats/bookings',
    ],
  },
  {
    id: 36,
    privilege: 'Interhome',
    permittedUrls: [
      '/siteadmin/interhome/listings',
      '/siteadmin/interhome/bookings',
    ],
  },
  {
    id: 37,
    privilege: 'Manage Hostfully',
    permittedUrls: ['/siteadmin/hostfully', '/siteadmin/hostfully/bookings'],
  },
  {
    id: 38,
    privilege: 'Manage Rentalsunited',
    permittedUrls: [
      '/siteadmin/rentals-united',
      '/siteadmin/rentals-united/bookings',
      '/siteadmin/rentals-united/rate',
    ],
  },
  {
    id: 39,
    privilege: 'Pending Member',
    permittedUrls: ['/siteadmin/member-pending'],
  },
  {
    id: 40,
    privilege: 'Manage NextPax',
    permittedUrls: ['/siteadmin/nextPax', '/siteadmin/nextPax/bookings'],
  },
  {
    id: 41,
    privilege: 'Manage GoSolo Trips',
    permittedUrls: [
      '/siteadmin/go-solo',
      '/siteadmin/add-trip',
      '/siteadmin/edit-trip',
      '/siteadmin/go-solo-booking',
    ],
  },
  {
    id: 42,
    privilege: 'Manage Inquiry',
    permittedUrls: ['/siteadmin/inquiry'],
  },
  {
    id: 43,
    privilege: 'OwnerRez',
    permittedUrls: [
      '/siteadmin/ownerrez/listings',
      '/siteadmin/ownerrez/bookings',
    ],
  },
  {
    id: 43,
    privilege: 'Manage Membership',
    permittedUrls: [
      '/siteadmin/subscription',
      '/siteadmin/subscription-block-1',
      '/siteadmin/subscription-block-2',
      '/siteadmin/subscription-block-3',
    ],
  },
  {
    id: 46,
    privilege: 'Manage Diamond Member',
    permittedUrls: [
      '/siteadmin/diamond-member',
      '/siteadmin/diamond-member/add',
      '/siteadmin/diamond-member/edit',
      '/siteadmin/diamond-member-offer',
    ],
  },
  {
    id: 45,
    privilege: 'Manage Local spots',
    permittedUrls: ['/siteadmin/localspots'],
  },
  {
    id: 44,
    privilege: 'Admin Logs',
    permittedUrls: ['/siteadmin/admin_logs'],
  },
];

export function getAllAdminPrivileges() {
  return privileges;
}

export function getAllAdminPrivilegesId() {
  return privileges.map((item) => item.id);
}

export function validatePrivilege(requestId, permittedPrevileges) {
  return (
    permittedPrevileges &&
    permittedPrevileges.length > 0 &&
    permittedPrevileges.indexOf(requestId) >= 0
  );
}

export function restrictUrls(requestURL, permittedPrevileges) {
  const findRequestedUrlId = privileges.find(
    (o) =>
      o &&
      o.permittedUrls &&
      o.permittedUrls.length > 0 &&
      o.permittedUrls.indexOf(requestURL) >= 0
  );

  if (findRequestedUrlId) {
    const checkAccess =
      permittedPrevileges &&
      permittedPrevileges.length &&
      permittedPrevileges.indexOf(findRequestedUrlId.id) >= 0;
    return !!checkAccess;
  }
  return false;
}
