import { gql } from 'react-apollo';
import { toastr } from 'react-redux-toastr';

import { adminEmaillist } from '../../config';
import {
  SEND_FORGOT_PASSWORD_ERROR,
  SEND_FORGOT_PASSWORD_START,
  SEND_FORGOT_PASSWORD_SUCCESS,
} from '../../constants';
import { sendEmail } from '../../core/email/sendEmail';
import { closeForgotPasswordModal } from '../modalActions';

export function sendForgotLink(email) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: SEND_FORGOT_PASSWORD_START,
    });

    dispatch(closeForgotPasswordModal());

    try {
      const mutation = gql`
        mutation sendForgotPassword($email: String!) {
          sendForgotPassword(email: $email) {
            id
            email
            token
            userId
            status
            profile {
              firstName
            }
          }
        }
      `;

      // Send Message
      const { data } = await client.mutate({
        mutation,
        variables: {
          email,
        },
      });

      if (data && data.sendForgotPassword) {
        if (data.sendForgotPassword.status === 'notAvailable') {
          toastr.error(
            'Send Reset Link Failed',
            "We can't find an account for this email. Please try again."
          );
          return false;
        }
        if (data.sendForgotPassword.status === '400') {
          toastr.error(
            'Send Reset Link Failed',
            'Something went wrong. please try again later.'
          );
          return false;
        }
        toastr.success(
          'Reset Link Sent to your email',
          'Reset link email is on its way to your inbox'
        );
        const content = {
          token: data.sendForgotPassword.token,
          email: data.sendForgotPassword.email,
          name: data.sendForgotPassword.profile.firstName,
        };
        await sendEmail(email, 'forgotPasswordLink', content);
        await sendEmail(adminEmaillist, 'forgotPasswordLink', {
          ...content,
          isAdmin: true,
        });
        dispatch({
          type: SEND_FORGOT_PASSWORD_SUCCESS,
        });
      }
    } catch (error) {
      dispatch({
        type: SEND_FORGOT_PASSWORD_ERROR,
        payload: {
          error,
        },
      });
      return false;
    }

    return true;
  };
}
