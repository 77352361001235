import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import account from './account';
import blogContentExplore from './blogContentExplore';
import blogMyPostModal from './blogMyPostModal';
import blogPostModal from './blogPostModal';
import blogUserPostModal from './blogUserPostModal';
import book from './book';
import bookGoSolo from './bookGoSolo';
import calendar from './calendar';
import connectUser from './connectUser';
import connectionMember from './connectionMember';
import content from './content';
import currency from './currency';
import getIndustries from './getIndustry';
import getUniversities from './getUniversity';
import goSocial from './goSocial';
import idmModal from './idmModal';
import industry from './industry';
import interhome from './interhome';
import intl from './intl';
import location from './listYourSpace';
import listingFields from './listingFields';
import listingOverrides from './listingOverrides';
import listingPhotos from './listingPhotos';
import loader from './loader';
import memberForm from './memberForm';
import mobileSearch from './mobileSearch';
import modalStatus from './modalReducer';
import nextPax from './nextPax';
import onChangeListing from './onChangeListing';
import ownerrez from './ownerrez';
import payout from './payout';
import payoutChangeListing from './payoutChangeListing';
import personalized from './personalized';
import phoneVerification from './phoneVerification';
import pressUserData from './pressUserData';
import referral from './referral';
import reservation from './reservation';
import route from './route';
import runtime from './runtime';
import search from './search';
import siteSettings from './siteSettings';
import iconUploadLoader from './siteadmin/IconUploadLoader/iconUploadLoader';
import getBeds from './siteadmin/Integration/getBeds';
import adminListSettingsData from './siteadmin/adminListSettingsData';
import adminModalStatus from './siteadmin/adminModalReducer';
import adminPrevileges from './siteadmin/adminUserReducer';
import blogDetails from './siteadmin/blogDetails';
import homeBannerImages from './siteadmin/homeBannerImages';
import hostfully from './siteadmin/hostfully';
import listSettings from './siteadmin/listSettings';
import adminLogs from './siteadmin/logs';
import naturalRetreatsBookings from './siteadmin/naturalRetreatsBookings';
import nextPaxBookings from './siteadmin/nextPaxBookings';
import rentalsUnitedBookings from './siteadmin/rentalsUnitedBookings';
import staticLocationDetails from './siteadmin/staticLocationDetails';
import userManagement from './siteadmin/users';
import sticky from './stickyReducers';
import storeGender from './storeGender';
import stripeStates from './stripeStates';
import toggle from './toggle';
import user from './user';
import verificationModal from './verificationModal';
import viewListing from './viewListing';
import yourListings from './yourListings';

export default function createRootReducer({ apolloClient }) {
  return combineReducers({
    apollo: apolloClient.reducer(),
    // loadingBar: loadingBarReducer,
    user,
    runtime,
    interhome,
    goSocial,
    intl,
    siteSettings,
    form: formReducer,
    content,
    account,
    userManagement,
    toastr: toastrReducer,
    location,
    modalStatus,
    listSettings,
    adminModalStatus,
    listingFields,
    adminListSettingsData,
    viewListing,
    currency,
    search,
    toggle,
    personalized,
    mobileSearch,
    book,
    nextPax,
    reservation,
    loader,
    calendar,
    sticky,
    storeGender,
    referral,
    connectUser,
    getIndustries,
    iconUploadLoader,
    payout,
    getUniversities,
    blogDetails,
    staticLocationDetails,
    memberForm,
    homeBannerImages,
    stripeStates,
    pressUserData,
    onChangeListing,
    payoutChangeListing,
    phoneVerification,
    verificationModal,
    idmModal,
    industry,
    connectionMember,
    blogContentExplore,
    blogPostModal,
    blogUserPostModal,
    blogMyPostModal,
    getBeds,
    adminPrevileges,
    route,
    bookGoSolo,
    yourListings,
    listingOverrides,
    hostfully,
    adminLogs,
    nextPaxBookings,
    rentalsUnitedBookings,
    naturalRetreatsBookings,
    listingPhotos,
    ownerrez,
  });
}
