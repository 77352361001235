import { initialize } from 'redux-form';

import {
  CLOSE_ADMIN_ROLES_MODAL,
  CLOSE_ADMIN_USER_MODAL,
  CLOSE_LIST_SETTINGS_MODAL,
  CLOSE_MAXINVITE_SETTINGS_MODAL,
  CLOSE_TAG_MODAL,
  HOST_BANNER_UPLOAD_START,
  HOST_BANNER_UPLOAD_STOP,
  HOST_LOGO_UPLOAD_START,
  HOST_LOGO_UPLOAD_STOP,
  MEMBER_BANNER_UPLOAD_START,
  MEMBER_BANNER_UPLOAD_STOP,
  MEMBER_LOGO_UPLOAD_START,
  MEMBER_LOGO_UPLOAD_STOP,
  OPEN_ADMIN_ROLES_MODAL,
  OPEN_ADMIN_USER_MODAL,
  OPEN_LIST_SETTINGS_MODAL,
  OPEN_MAXINVITE_SETTINGS_MODAL,
  OPEN_TAG_MODAL,
  UPDATE_SUBSCRIPTION_FORM_MODAL,
  UPDATE_RESET_PASSWORD_MODAL,
  UPDATE_GO_SOLO_PRICE_SPLIT_UP_MODAL,
} from '../../constants';

export function openListSettingsModal(initialData, formName) {
  return (dispatch, getState) => {
    // Reinitialize the form values
    dispatch(initialize(formName, initialData, true));

    dispatch({
      type: OPEN_LIST_SETTINGS_MODAL,
      listSettingsModal: true,
    });
  };
}

export function openInviteSettingsModal(initialData, formName) {
  return (dispatch, getState) => {
    // Reinitialize the form values
    dispatch(initialize(formName, initialData, true));

    dispatch({
      type: OPEN_MAXINVITE_SETTINGS_MODAL,
      inviteSettingsModal: true,
      uploadFileName: '',
    });
  };
}

export function openEditListSettingsModal(initialData) {
  return (dispatch, getState) => {
    // Reinitialize the form values
    dispatch(initialize('EditListSettingsForm', initialData, true));

    dispatch({
      type: OPEN_LIST_SETTINGS_MODAL,
      listSettingsModal: true,
    });
  };
}

export function closeListSettingsModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_LIST_SETTINGS_MODAL,
      listSettingsModal: false,
    });
  };
}

export function closeInviteSettingsModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_MAXINVITE_SETTINGS_MODAL,
      inviteSettingsModal: false,
    });
  };
}

export function startLogoUpload() {
  return (dispatch, getState, { client }) => {
    dispatch({
      type: MEMBER_LOGO_UPLOAD_START,
      payload: {
        isLogoUpload: true,
      },
    });
  };
}

export function stopLogoUpload() {
  return (dispatch, getState, { client }) => {
    dispatch({
      type: MEMBER_LOGO_UPLOAD_STOP,
      payload: {
        isLogoUpload: false,
      },
    });
  };
}

export function startBannerUpload() {
  return (dispatch, getState, { client }) => {
    dispatch({
      type: MEMBER_BANNER_UPLOAD_START,
      payload: {
        isBannerLogo: true,
      },
    });
  };
}

export function stopBannerUpload() {
  return (dispatch, getState, { client }) => {
    dispatch({
      type: MEMBER_BANNER_UPLOAD_STOP,
      payload: {
        isBannerLogo: false,
      },
    });
  };
}

// FAQ
export function startHostLogoUpload() {
  return (dispatch, getState, { client }) => {
    dispatch({
      type: HOST_LOGO_UPLOAD_START,
      payload: {
        isLogoUpload: true,
      },
    });
  };
}

export function stopHostLogoUpload() {
  return (dispatch, getState, { client }) => {
    dispatch({
      type: HOST_LOGO_UPLOAD_STOP,
      payload: {
        isLogoUpload: false,
      },
    });
  };
}

export function startHostBannerUpload() {
  return (dispatch, getState, { client }) => {
    dispatch({
      type: HOST_BANNER_UPLOAD_START,
      payload: {
        isBannerLogo: true,
      },
    });
  };
}

export function stopHostBannerUpload() {
  return (dispatch, getState, { client }) => {
    dispatch({
      type: HOST_BANNER_UPLOAD_STOP,
      payload: {
        isBannerLogo: false,
      },
    });
  };
}

export function openAdminUserModal(type, formData) {
  return (dispatch, getState) => {
    if (type === 'edit') {
      dispatch(initialize('AdminUserForm', formData, true));
    }

    dispatch({
      type: OPEN_ADMIN_USER_MODAL,
      payload: {
        adminUserModal: true,
        adminUserModalType: type,
      },
    });
  };
}

export function closeAdminUserModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_ADMIN_USER_MODAL,
      payload: {
        adminUserModal: false,
      },
    });
  };
}

export function openAdminRolesModal(type, formData) {
  return (dispatch, getState) => {
    if (type === 'edit') {
      dispatch(initialize('AdminRolesForm', formData, true));
    }

    dispatch({
      type: OPEN_ADMIN_ROLES_MODAL,
      payload: {
        adminRolesModal: true,
        adminRolesModalType: type,
      },
    });
  };
}

export function closeAdminRolesModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_ADMIN_ROLES_MODAL,
      payload: {
        adminRolesModal: false,
      },
    });
  };
}

export function openTagModal(type, formData) {
  return (dispatch, getState) => {
    if (type === 'edit') dispatch(initialize('TagsForm', formData, true));

    dispatch({
      type: OPEN_TAG_MODAL,
      payload: {
        tagModalStatus: true,
      },
    });
  };
}

export function closeTagModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_TAG_MODAL,
      payload: {
        tagModalStatus: false,
      },
    });
  };
}

export function openSubscriptionModal(formData) {
  return (dispatch, getState) => {
    dispatch(initialize('SubscriptionForm', formData, true));
    dispatch({
      type: UPDATE_SUBSCRIPTION_FORM_MODAL,
      payload: {
        isSubscriptionFormOpen: true,
      },
    });
  };
}

export function closeSubscriptionModal() {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_SUBSCRIPTION_FORM_MODAL,
      payload: {
        isSubscriptionFormOpen: false,
      },
    });
  };
}

export function openResetPasswordModal(formData) {
  return (dispatch, getState) => {
    dispatch(initialize('ResetPasswordForm', formData, true));
    dispatch({
      type: UPDATE_RESET_PASSWORD_MODAL,
      payload: {
        isResetPasswordModalOpen: true,
      },
    });
  };
}

export function closeResetPasswordModal() {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_RESET_PASSWORD_MODAL,
      payload: {
        isResetPasswordModalOpen: false,
      },
    });
  };
}

export function openGoSoloPriceSplitUpModal() {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_GO_SOLO_PRICE_SPLIT_UP_MODAL,
      payload: {
        isGoSoloPriceSplitUpOpen: true,
      },
    });
  };
}

export function closeGoSoloPriceSplitUpModal() {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_GO_SOLO_PRICE_SPLIT_UP_MODAL,
      payload: {
        isGoSoloPriceSplitUpOpen: false,
      },
    });
  };
}
