import React, { Component } from 'react';

import PropTypes from 'prop-types';

import AcceptedMutualConnection from './AcceptedMutualConnection';
import AdminErrorMessage from './AdminErrorMessage';
import AdminListVerification from './AdminListVerification';
import AdminNewProperty from './AdminNewProperty';
import AdminReview from './AdminReview';
import AdminSocialBlogErrorMessage from './AdminSocialBlogErrorMessage';
import AdminTrip from './AdminTrip';
import AdvanceNoticePeriod from './AdvanceNoticePeriod';
import BookingConfirmationGuest from './BookingConfirmationGuest';
import BookingConfirmationHost from './BookingConfirmationHost';
import BookingDeclineCardToHost from './BookingDeclineCardToHost';
import BookingDeclinedGuest from './BookingDeclinedGuest';
import BookingDeclinedHost from './BookingDeclinedHost';
import BookingExpiredGuest from './BookingExpiredGuest';
import BookingExpiredHost from './BookingExpiredHost';
import BookingPreApproval from './BookingPreApproval';
import BookingPreApprovalAdmin from './BookingPreApprovalAdmin';
import BookingRequestGuest from './BookingRequestGuest';
import BookingRequestHost from './BookingRequestHost';
import CancelledByGuest from './CancelledByGuest';
import CancelledByGuestAdmin from './CancelledByGuestAdmin';
import CancelledByHost from './CancelledByHost';
import CancelledByHostAdmin from './CancelledByHostAdmin';
import ClaimBond from './ClaimBond';
import ClaimSecurityFeeGuest from './ClaimSecurityFeeGuest';
import ClaimSecurityFeeHost from './ClaimSecurityFeeHost';
import CompletedReservationGuest from './CompletedReservationGuest';
import CompletedReservationHost from './CompletedReservationHost';
import ConciergeRequestAcknowledge from './ConciergeRequestAcknowledge';
import ConciergeRequestAdmin from './ConciergeRequestAdmin';
import ConfirmDocumentVerification from './ConfirmDocumentVerification';
import ConfirmEmail from './ConfirmEmail';
import ConfirmIdProofVerification from './ConfirmIdProofVerification';
import ContactEmail from './ContactEmail';
import DeleteHosfullyProperty from './DeleteHosfullyProperty';
import DiamondSucess from './DiamondSucess';
import DocumentUpload from './DocumentUpload';
import DocumentVerificaionRejectedfromIDM from './DocumentVerificaionRejectedfromIDM';
import EventConfirmation from './EventConfirmation';
import EventConfirmed from './EventConfirmed';
import ForgotPasswordEmail from './ForgotPasswordEmail';
import FormalRegisterAdminEmail from './FormalRegisterAdminEmail';
import FormalRegisterMemberEmail from './FormalRegisterMemberEmail';
import GuestTrip from './GuestTrip';
import HostTrip from './HostTrip';
import InquiryDeclineAdmin from './InquiryDeclineAdmin';
import InquiryDeclineGuest from './InquiryDeclineGuest';
import InquiryForAdmin from './InquiryForAdmin';
import InviteEmailAdmin from './InviteEmailAdmin';
import LinkedinFemaleUserEmail from './LinkedinFemaleUserEmail';
import ListPublishRequest from './ListPublishRequest';
import MaleUserContent from './MaleUserContent';
import ManualReviewStatus from './ManualReviewStatus';
import MemberApproved from './MemberApproved';
import MemberDeclinedEmail from './MemberDeclinedEmail';
import MemberDetails from './MemberDetails';
import MessageAdmin from './MessageAdmin';
import NewInquiry from './NewInquiry';
import NewMessage from './NewMessage';
import NewUserEmail from './NewUserEmail';
import NormalFemaleUserEmail from './NormalFemaleUserEmail';
import OfferApproved from './OfferApproved';
import OfferApprovedAdmin from './OfferApprovedAdmin';
import OfferGuestAccept from './OfferGuestAccept';
import OfferGuestAcceptAdmin from './OfferGuestAcceptAdmin';
import OfferGuestDecline from './OfferGuestDecline';
import OfferGuestDeclineAdmin from './OfferGuestDeclineAdmin';
import OfferHostDecline from './OfferHostDecline';
import OfferHostDeclineAdmin from './OfferHostDeclineAdmin';
import OfferHostRequest from './OfferHostRequest';
import OfferHostRequestAdmin from './OfferHostRequestAdmin';
import PhotoUploadReminder from './PhotoUploadReminder';
import PrivateMessage from './PrivateMessage';
import PropertyManagerInvite from './PropertyManagerInvite';
import PropertyUnlist from './PropertyUnlist';
import ReaminderBookingAdmin from './ReaminderBookingAdmin';
import ReferralInvitationEmail from './ReferralInvitationEmail';
import RejectedStatusfromIDM from './RejectedStatusfromIDM';
import RemainderAdminBookingBefore from './RemainderAdminBookingBefore';
import RemainderGuestBooking from './RemainderGuestBooking';
import RemainderGuestBookingBefore from './RemainderGuestBookingBefore';
import RemainderHostBooking from './RemainderHostBooking';
import RemainderHostBookingBefore from './RemainderHostBookingBefore';
import ReportUserMail from './ReportUserMail';
import RequestConnection from './RequestConnection';
import RequestConnectionAdmin from './RequestConnectionAdmin';
import RequestMembershipSubscription from './RequestMembershipSubscription';
import ReservationConfirmForAdmin from './ReservationConfirmForAdmin';
import ReservationDeclineForHost from './ReservationDeclineForHost';
import ReservationForAdmin from './ReservationForAdmin';
import SecondaryEmailInvite from './SecondaryEmailInvite';
import SendGoSoloPaymentLink from './SendGoSoloPaymentLink';
import SignUpApprovedinIDM from './SignUpApprovedinIDM';
import SignUpPendingIDM from './SignUpPendingIDM';
import SocialBlogPostApproval from './SocialBlogPostApproval';
import SyncProperty from './SyncProperty';
import SyncPropertyHost from './SyncPropertyHost';

class EmailTemplate extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired,
  };

  render() {
    const { type, content } = this.props;
    return (
      <div>
        {type === 'newUserEmail' && <NewUserEmail content={content} />}
        {type === 'linkedinFemaleUserEmail' && (
          <LinkedinFemaleUserEmail content={content} />
        )}
        {type === 'normalFemaleUserEmail' && (
          <NormalFemaleUserEmail content={content} />
        )}
        {type === 'maleUserContent' && <MaleUserContent content={content} />}
        {type === 'welcomeEmail' && <ConfirmEmail content={content} />}
        {type === 'confirmEmail' && <ConfirmEmail content={content} />}
        {type === 'bookingRequest' && <BookingRequestHost content={content} />}
        {type === 'bookingRequestGuest' && (
          <BookingRequestGuest content={content} />
        )}
        {type === 'bookingConfirmedToHost' && (
          <BookingConfirmationHost content={content} />
        )}
        {type === 'bookingConfirmedToGuest' && (
          <BookingConfirmationGuest content={content} />
        )}
        {type === 'bookingDeclinedToGuest' && (
          <BookingDeclinedGuest content={content} />
        )}
        {type === 'bookingExpiredGuest' && (
          <BookingExpiredGuest content={content} />
        )}
        {type === 'bookingExpiredHost' && (
          <BookingExpiredHost content={content} />
        )}
        {type === 'cancelledByHost' && <CancelledByHost content={content} />}
        {type === 'cancelledByGuest' && <CancelledByGuest content={content} />}
        {type === 'completedGuest' && (
          <CompletedReservationGuest content={content} />
        )}
        {type === 'completedHost' && (
          <CompletedReservationHost content={content} />
        )}
        {type === 'forgotPasswordLink' && (
          <ForgotPasswordEmail content={content} />
        )}
        {type === 'message' && <NewMessage content={content} />}
        {type === 'inquiry' && <NewInquiry content={content} />}
        {type === 'documentVerification' && (
          <ConfirmDocumentVerification content={content} />
        )}
        {type === 'contact' && <ContactEmail content={content} />}
        {type === 'referralInvite' && (
          <ReferralInvitationEmail content={content} />
        )}
        {type === 'memberDecline' && <MemberDeclinedEmail content={content} />}
        {type === 'reportUser' && <ReportUserMail content={content} />}
        {type === 'memberDetails' && <MemberDetails content={content} />}
        {type === 'formalRegisterAdminEmail' && (
          <FormalRegisterAdminEmail content={content} />
        )}
        {type === 'formalRegisterMemberEmail' && (
          <FormalRegisterMemberEmail content={content} />
        )}
        {type === 'requestConnection' && (
          <RequestConnection content={content} />
        )}
        {type === 'acceptedMutualConnection' && (
          <AcceptedMutualConnection content={content} />
        )}
        {type === 'privateMessageRequest' && (
          <PrivateMessage content={content} />
        )}
        {type === 'remindPhotoUpload' && (
          <PhotoUploadReminder content={content} />
        )}
        {type === 'listPublishRequest' && (
          <ListPublishRequest content={content} />
        )}
        {type === 'adminListApprove' && (
          <AdminListVerification content={content} />
        )}
        {type === 'adminListReject' && (
          <AdminListVerification content={content} />
        )}
        {type === 'adminNewProperty' && <AdminNewProperty content={content} />}
        {type === 'checkInSafeHost' && <HostTrip content={content} />}
        {type === 'checkInSafeGuest' && <GuestTrip content={content} />}
        {type === 'checkInSafeAdmin' && <AdminTrip content={content} />}
        {type === 'claimSecurityFee' && <ClaimBond content={content} />}
        {type === 'deleteHosfullyProperty' && (
          <DeleteHosfullyProperty content={content} />
        )}
        {type === 'propertyManagerInvite' && (
          <PropertyManagerInvite content={content} />
        )}
        {type === 'secondaryEmailInvite' && (
          <SecondaryEmailInvite content={content} />
        )}
        {type === 'idProofVerification' && (
          <ConfirmIdProofVerification content={content} />
        )}
        {type === 'claimSecurityFeeHost' && (
          <ClaimSecurityFeeHost content={content} />
        )}
        {type === 'claimSecurityFeeGuest' && (
          <ClaimSecurityFeeGuest content={content} />
        )}
        {type === 'adminNewInvite' && <InviteEmailAdmin content={content} />}
        {type === 'messageAdmin' && <MessageAdmin content={content} />}
        {type === 'documentUpload' && <DocumentUpload content={content} />}
        {type === 'bookingRequestAdmin' && (
          <ReservationForAdmin content={content} />
        )}
        {type === 'bookingConfirmAdmin' && (
          <ReservationConfirmForAdmin content={content} />
        )}
        {type === 'inquiryAdmin' && <InquiryForAdmin content={content} />}
        {type === 'cancelledByHostAdmin' && (
          <CancelledByHostAdmin content={content} />
        )}
        {type === 'cancelledByGuestAdmin' && (
          <CancelledByGuestAdmin content={content} />
        )}
        {type === 'bookingDeclineAdmin' && (
          <ReservationConfirmForAdmin content={content} />
        )}
        {type === 'reviewSubmitted' && <AdminReview content={content} />}
        {type === 'requestConnectionAdmin' && (
          <RequestConnectionAdmin content={content} />
        )}
        {type === 'advanceNoticePeriod' && (
          <AdvanceNoticePeriod content={content} />
        )}
        {type === 'signUpPending' && <SignUpPendingIDM content={content} />}
        {type === 'signUpApproved' && <SignUpApprovedinIDM content={content} />}
        {type === 'signUpRejected' && (
          <RejectedStatusfromIDM content={content} />
        )}
        {type === 'documentIDMRejected' && (
          <DocumentVerificaionRejectedfromIDM content={content} />
        )}

        {type === 'manualReviewStatus' && (
          <ManualReviewStatus content={content} />
        )}
        {type === 'memberAccept' && <MemberApproved content={content} />}
        {type === 'messageAdminError' && (
          <AdminErrorMessage content={content} />
        )}
        {type === 'offerPriceInquiryAdmin' && (
          <InquiryForAdmin content={content} />
        )}
        {type === 'specialPriceInquiry' && <NewInquiry content={content} />}
        {type === 'offerApproved' && <OfferApproved content={content} />}
        {type === 'offerApprovedAdmin' && (
          <OfferApprovedAdmin content={content} />
        )}
        {type === 'offerHostDecline' && <OfferHostDecline content={content} />}
        {type === 'offerHostDeclineAdmin' && (
          <OfferHostDeclineAdmin content={content} />
        )}
        {type === 'offerHostRequest' && <OfferHostRequest content={content} />}
        {type === 'offerHostRequestAdmin' && (
          <OfferHostRequestAdmin content={content} />
        )}
        {type === 'offerGuestDecline' && (
          <OfferGuestDecline content={content} />
        )}
        {type === 'offerGuestDeclineAdmin' && (
          <OfferGuestDeclineAdmin content={content} />
        )}
        {type === 'offerGuestAccept' && <OfferGuestAccept content={content} />}
        {type === 'offerGuestAcceptAdmin' && (
          <OfferGuestAcceptAdmin content={content} />
        )}
        {type === 'bookingDeclinedToHost' && (
          <BookingDeclinedHost content={content} />
        )}
        {type === 'bookingDeclineToAdmin' && (
          <ReservationDeclineForHost content={content} />
        )}
        {type === 'bookingDeclinedCardToGuest' && (
          <BookingDeclineCardToHost content={content} />
        )}
        {type === 'bookingPreApproval' && (
          <BookingPreApproval content={content} />
        )}
        {type === 'bookingPreApprovalAdmin' && (
          <BookingPreApprovalAdmin content={content} />
        )}
        {type === 'conciergeRequestAdmin' && (
          <ConciergeRequestAdmin content={content} />
        )}
        {type === 'conciergeRequestAcknowledge' && (
          <ConciergeRequestAcknowledge content={content} />
        )}
        {type === 'remainderHostBooking' && (
          <RemainderHostBooking content={content} />
        )}
        {type === 'remainderGuestBooking' && (
          <RemainderGuestBooking content={content} />
        )}
        {type === 'remainderAdminBooking' && (
          <ReaminderBookingAdmin content={content} />
        )}
        {type === 'syncListing' && <SyncProperty content={content} />}
        {type === 'remainderHostBookingBefore' && (
          <RemainderHostBookingBefore content={content} />
        )}
        {type === 'remainderGuestBookingBefore' && (
          <RemainderGuestBookingBefore content={content} />
        )}
        {type === 'remainderAdminBookingBefore' && (
          <RemainderAdminBookingBefore content={content} />
        )}
        {type === 'syncListingHost' && <SyncPropertyHost content={content} />}
        {type === 'socialBlogPostApproval' && (
          <SocialBlogPostApproval content={content} />
        )}
        {type === 'eventConfirmation' && (
          <EventConfirmation content={content} />
        )}
        {type === 'propertyUnlist' && <PropertyUnlist content={content} />}
        {type === 'inquiryDecline' && <InquiryDeclineGuest content={content} />}
        {type === 'inquiryDeclineAdmin' && (
          <InquiryDeclineAdmin content={content} />
        )}
        {type === 'eventConfirmed' && <EventConfirmed content={content} />}
        {type === 'sendGoSoloPaymentLink' && (
          <SendGoSoloPaymentLink content={content} />
        )}
        {type === 'restrictionMessageOnSocialBlog' && (
          <AdminSocialBlogErrorMessage content={content} />
        )}
        {type === 'diamondSucess' && <DiamondSucess content={content} />}
        {type === 'requestMembershipSubscription' && (
          <RequestMembershipSubscription content={content} />
        )}
      </div>
    );
  }
}

export default EmailTemplate;
