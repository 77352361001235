import React, { Component } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { change, submit as submitForm } from 'redux-form';

import { setPersonalizedValues } from '../../../actions/personalized';
import history from '../../../core/history';
import messages from '../../../locale/messages';
import GoogleMapsLoader from '../../GoogleMaps/GoogleMapsLoader';

import CustomGeoSuggest from './CustomGeoSuggest';
import c from './HeaderLocationSearch.module.css';

class HeaderLocationSearch extends Component {
  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    setPersonalizedValues: PropTypes.func,
    googleMaps: PropTypes.object,
    personalized: PropTypes.shape({
      location: PropTypes.string,
      lat: PropTypes.number,
      lng: PropTypes.number,
      geography: PropTypes.string,
    }),
  };

  static defaultProps = {
    personalized: {
      location: '',
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      locationValue: '',
    };
    this.onSuggestSelect = this.onSuggestSelect.bind(this);
    this.setRef = this.setRef.bind(this);
  }

  componentDidMount() {
    const { personalized } = this.props;
    if (personalized && personalized.location) {
      this.setState({
        locationValue: personalized.location,
      });
    }
  }

  UNSAFE_componentWillReceiveProps({ personalized = {} }) {
    const { personalized: newPers = {} } = this.props;
    if (personalized && newPers && personalized.location !== newPers.location)
      this.setState({
        locationValue: personalized.location,
      });
    if (!personalized.location && personalized.location !== newPers.location)
      this.onSuggestSelect();
  }

  onSuggestSelect(data) {
    const { setPersonalizedValues } = this.props;
    let defaultQuery = history && history.location;
    // defaultQuery = defaultQuery.pathname && defaultQuery.pathname.split('refinement_paths=')[1];
    if (history) defaultQuery = history.location && history.location.search;
    const defaultParams = queryString.parse(defaultQuery, {
      arrayFormat: 'separator',
      arrayFormatSeparator: '|',
    });
    if (!defaultQuery) {
      defaultParams.personCapacity = 1;
      defaultParams.womenCount = 1;
    }
    const locationData = {};
    let types = [];
    let geoType;
    if (data && data.gmaps) {
      types = data.gmaps.types;
      data.gmaps.address_components.forEach((item, key) => {
        if (item.types[0] === 'administrative_area_level_1') {
          locationData.administrative_area_level_1_short = item.short_name;
          locationData.administrative_area_level_1_long = item.long_name;
        } else if (item.types[0] === 'country') {
          locationData[item.types[0]] = item.short_name;
        } else {
          locationData[item.types[0]] = item.long_name;
        }
      });

      if (types && types.length > 0) {
        if (types.indexOf('country') > -1) {
          geoType = 'country';
        } else if (types.indexOf('administrative_area_level_1') > -1) {
          geoType = 'state';
        } else {
          geoType = null;
        }
      }
      setPersonalizedValues({
        name: 'geography',
        value: JSON.stringify(locationData),
      });
      setPersonalizedValues({ name: 'geoType', value: geoType });
      setPersonalizedValues({ name: 'location', value: data.label });
      setPersonalizedValues({ name: 'lat', value: data.location.lat });
      setPersonalizedValues({ name: 'lng', value: data.location.lng });
      setPersonalizedValues({ name: 'chosen', value: 1 });
      defaultParams.address = data.label;
      defaultParams.chosen = 1;
      history.push(
        `/s/refinement_paths?${queryString.stringify(defaultParams, {
          arrayFormat: 'separator',
          arrayFormatSeparator: '|',
          skipNull: true,
        })}`
      );
    } else {
      setPersonalizedValues({ name: 'geography', value: null });
      setPersonalizedValues({ name: 'geoType', value: null });
      setPersonalizedValues({ name: 'location', value: null });
      setPersonalizedValues({ name: 'lat', value: null });
      setPersonalizedValues({ name: 'lng', value: null });
      setPersonalizedValues({ name: 'chosen', value: null });
      defaultParams.address = null;
      defaultParams.chosen = null;
      this.geoSuggest && this.geoSuggest.clear();
      defaultQuery &&
        history.push(
          `/s/refinement_paths?${queryString.stringify(defaultParams, {
            arrayFormat: 'separator',
            arrayFormatSeparator: '|',
            skipNull: true,
          })}`
        );
    }
  }

  setRef(ref) {
    const { setPersonalizedValues } = this.props;
    this.geoSuggest = ref;
    setPersonalizedValues({ name: 'headerLocationRef', value: ref });
  }

  render() {
    const { className, containerClassName } = this.props;
    const { formatMessage } = this.props.intl;
    const { locationValue } = this.state;

    return (
      <div className="headerSearch">
        <GoogleMapsLoader>
          <div className={cx(c.displayTable, c.searchContainer)}>
            <div className={cx(c.displayTableCell, c.searchIconContainer)}>
              <svg
                aria-hidden="true"
                className={c.searchIcon}
                focusable="false"
                role="presentation"
                viewBox="0 0 24 24"
              >
                <path d="m10.4 18.2c-4.2-.6-7.2-4.5-6.6-8.8.6-4.2 4.5-7.2 8.8-6.6 4.2.6 7.2 4.5 6.6 8.8-.6 4.2-4.6 7.2-8.8 6.6m12.6 3.8-5-5c1.4-1.4 2.3-3.1 2.6-5.2.7-5.1-2.8-9.7-7.8-10.5-5-.7-9.7 2.8-10.5 7.9-.7 5.1 2.8 9.7 7.8 10.5 2.5.4 4.9-.3 6.7-1.7v.1l5 5c .3.3.8.3 1.1 0s .4-.8.1-1.1" />
              </svg>
            </div>
            <div className={c.displayTableCell}>
              <CustomGeoSuggest
                ref={this.setRef}
                className={containerClassName}
                googleMaps={window.google?.maps}
                initialValue={locationValue}
                inputClassName={className}
                onSuggestNoResults={() => {}}
                onSuggestSelect={this.onSuggestSelect}
                placeholder={formatMessage(messages.homeWhere)}
              />
            </div>
          </div>
        </GoogleMapsLoader>
      </div>
    );
  }
}

const mapState = (state) => ({
  personalized: state.personalized,
});

const mapDispatch = {
  setPersonalizedValues,
  change,
  submitForm,
};

export default injectIntl(connect(mapState, mapDispatch)(HeaderLocationSearch));
