const env = process.env;
const environment = env.REACT_APP_ENV;

export const maxVideUploadSize = 50;
export const url = env.REACT_APP_WEBSITE_URL;
export const sitename = env.REACT_APP_SITENAME;
export const staticPictureUrl = env.REACT_APP_WEBSITE_URL_PIC_STATIC;
export const uploadPictureUrl = env.REACT_APP_WEBSITE_URL_PIC_UPLOAD;
export const profilePictureUrl = env.REACT_APP_WEBSITE_URL_PIC_PROFILE;
export const graphqlApi = env.REACT_APP_GRAPHQL_API;
export const golightlyDefault = env.REACT_APP_DEFAULT_PIC_URL;
export const cloudFrontUrl = env.REACT_APP_CLOUDFRONT_URL;
export const backendUrl = env.REACT_APP_BACKEND_URL;
export const domainUrl = env.REACT_APP_DOMAIN_URL;
export const uploadPictureMediumUrl = env.REACT_APP_FILEUPLOAD_DIR;

// Referral Url
export const referralUrl = `${url}/c/`;

// default locale is the first one
export const locales = ['en-US', 'cs-CZ'];

// Listing Photos Upload Directory
export const fileuploadDir = env.REACT_APP_FILEUPLOAD_DIR;

export const googleMapAPI = env.REACT_APP_GOOGLE_MAP_API;

// site key for google recaptcha
export const googleCaptcha = {
  sitekey: '6LcKa1MUAAAAAGT1914w0-yhcVoBfKWoXO64WhKp',
};

export const payment = {
  stripe: {
    publishableKey: env.REACT_APP_STRIPE_PK,
  },
};
export const emailConfig = {
  host: env.REACT_APP_SMTP_HOST,
  port: env.REACT_APP_SMTP_PORT,
  email: env.REACT_APP_SMTP_LOGIN_EMAIL,
  sender: env.REACT_APP_SMTP_FROM_NAME,
  senderEmail: env.REACT_APP_SMTP_SENDER_EMAIL,
  password: env.REACT_APP_SMTP_LOGIN_PASSWORD,
  secure: env.REACT_APP_SMTP_SECURE,
  tls: env.REACT_APP_SMTP_TLS,
};

const devBccEmailList = ['sadam@radicalstart.com', 'nikitasednor@gmail.com'];
const prodBccEmailList = [
  'kat@wegolightly.com',
  'victoria@wegolightly.com',
  'james@wegolightly.com',
  'sadam@radicalstart.com',
  'nikitasednor@gmail.com',
];

export const hostfullyBookingEmail = 'kat@wegolightly.com';

export const bccEmailList =
  environment === 'prod' ? prodBccEmailList : devBccEmailList;

export const auth = {
  jwt: { secret: env.REACT_APP_JWT_SECRET },

  redirectURL: {
    login: env.REACT_APP_LOGIN_URL,
    verification: env.REACT_APP_LOGIN_URL,
    invalidReferral: env.REACT_APP_LOGIN_URL,
    invalidReferralEmail: env.REACT_APP_LOGIN_URL,
  },

  // https://developers.facebook.com/
  facebook: {
    id: env.REACT_APP_FACEBOOK_APP_ID,
    secret: env.REACT_APP_FACEBOOK_APP_SECRET,
    returnURL: env.REACT_APP_FACEBOOK_CLIENT_URL,
  },

  // https://cloud.google.com/console/project
  google: {
    id: env.REACT_APP_GOOGLE_CLIENT_ID,
    secret: env.REACT_APP_GOOGLE_CLIENT_SECRET,
    returnURL: env.REACT_APP_GOOGLE_CLIENT_URL,
  },
  // https://developer.linkedin.com
  linkedin: {
    id: env.REACT_APP_linkedin_CLIENT_ID,
    secret: env.REACT_APP_linkedin_CLIENT_SECRET,
    returnURL: env.REACT_APP_linkedin_CLIENT_URL,
  },

  adminEmail: {
    email: 'holly@wegolightly.com',
  },
};

const devAdminEmaillist = ['sadam@radicalstart.com', 'nikitasednor@gmail.com'];
const prodAdminEmaillist = [
  'kat@wegolightly.com',
  'victoria@wegolightly.com',
  'james@wegolightly.com',
  'sadam@radicalstart.com',
  'nikitasednor@gmail.com',
];

export const adminEmaillist =
  environment === 'prod' ? prodAdminEmaillist : devAdminEmaillist;

export const sentryURL = env.REACT_APP_SENTRY_URL;
export const walkScoreAPIKey = env.REACT_APP_WALK_SCORE_API_KEY;
export const rapidAPIKey = env.REACT_APP_RAPID_API_KEY;

export const maxUploadSize = 10;

export const getBlogPicUrl = `${backendUrl}/images/blog/`;

export const blogImageuploadDir = './images/blog';

export const proofuploadUrl = env.REACT_APP_WEBSITE_URL_PIC_UPLOAD;

export const toastrOptions = {
  timeOut: 7000,
};

export const hostFullyMainUrl = 'https://api.hostfully.com';
export const hostFullyVersionOne = 'v1';
export const hostfullyApiList = {
  pricing: 'pricingperiods',
  agencies: 'agencies',
  properties: 'properties',
  webhooks: 'webhooks',
  leads: 'leads',
  reviews: 'reviews',
  pricingBulk: 'pricingperiodsbulk',
  photos: 'photos',
  propertyDescription: 'propertydescriptions',
  amenities: 'amenities',
  bedTypes: 'bedtypes',
};

export const cropProfilePhotouploadDir = env.REACT_APP_PROFILE_PHOTO_UPLOAD_DIR;

export const lazyPictureUrl = env.REACT_APP_WEBSITE_URL;

export const hostFullyAPIKey = 'GLTY-2354572057438022553225';

export const taxRateUrl =
  'https://www.avalara.com/mylodgetax/en/resources/rate-lookup/golightly.html?partnersource=0010b00002cJ94UAAS&CampaignID=7010b0000019UhPAAU&campaignname=MLT202008%20MyLodgeTax%20Free%20Rates%20GoLightly%20Partner%20Referral&lsmr=Direct%20Partner%20Referral&lso=Direct%20Partner%20Referral';

export const reviewGuest =
  'https://www.wegolightly.com/page/golightly-covid-response';

export const getEventImageUrl = '/images/events';

export const getFileUrl = `${backendUrl}/images/upload/`;

export const getHomeBannerImageUrl = `${backendUrl}/images/home/`;

export const getImageBannerImageUrl = `${backendUrl}/images/banner/`;

export const getLogoUrl = `${backendUrl}/images/logo/`;

export const insurancePercentage = 7;

export const staticUserId = [
  '204b60d0-4bdc-11e9-a171-49c0b4e20fdc',
  '5d46f520-a919-11eb-ab1f-077c4107775e',
];

export const externalBlog = [23];

const devSocialAdminEmail = [
  'sadam@radicalstart.com',
  'nikitasednor@gmail.com',
];
const prodSocialAdminEmail = [
  'kat@wegolightly.com',
  'victoria@wegolightly.com',
  'james@wegolightly.com',
  'sadam@radicalstart.com',
  'nikitasednor@gmail.com',
  'martine@wegolightly.com',
];

export const socialAdminEmail =
  environment === 'prod' ? prodSocialAdminEmail : devSocialAdminEmail;

export const cookieKey = env.REACT_APP_COOKIE_KEY;

export const getGoSoloImageUrl = `${backendUrl}/images/gosolo/`;

export const blogCommentImageUrl = `${backendUrl}/images/blogComment/`;
