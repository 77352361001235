import axios from 'axios';
import cookie from 'react-cookies';
import { change, initialize, reset } from 'redux-form';

import { backendUrl } from '../config';
import {
  CLOSE_BOOKING_MODAL,
  CLOSE_CONNECTION_MODAL,
  CLOSE_CONNECTION_NOTE_MODAL,
  CLOSE_EDIT_POST_MODAL,
  CLOSE_EMAIL_MODAL,
  CLOSE_FORGOT_PASSWORD_MODAL,
  CLOSE_HOSTFULLY_AGENCY_MODAL,
  CLOSE_INPUT_PHONE_MODAL,
  CLOSE_LONGIN_MODAL,
  CLOSE_MESSAGE_MESSAGE_MODAL,
  CLOSE_MORE_FILTERS_MODAL,
  CLOSE_NEW_USER_INSERT_MODAL,
  CLOSE_PERSONAL_MODAL,
  CLOSE_POST_MODAL,
  CLOSE_PRESS_USER_MODAL,
  CLOSE_REFERRAL_INSERT_MODAL,
  CLOSE_REPORT_USER_MODAL,
  CLOSE_SERVICE_MODAL,
  CLOSE_SIGNUP_MODAL,
  CLOSE_SOCIAL_SHARE_MODAL,
  CLOSE_THANK_YOU_MODAL,
  MANAGE_SOCIAL_BLOG_SHARE_MODAL,
  MANAGE_SOCIAL_LIKE_MODAL,
  OPEN_BOOKING_MODAL,
  OPEN_CONNECTION_MODAL,
  OPEN_CONNECTION_NOTE_MODAL,
  OPEN_EMAIL_MODAL,
  OPEN_FORGOT_PASSWORD_MODAL,
  OPEN_HOSTFULLY_AGENCY_MODAL,
  OPEN_INPUT_PHONE_MODAL,
  OPEN_LONGIN_MODAL,
  OPEN_MESSAGE_MESSAGE_MODAL,
  OPEN_MORE_FILTERS_MODAL,
  OPEN_NEW_USER_INSERT_MODAL,
  OPEN_PERSONAL_MODAL,
  OPEN_POST_MODAL,
  OPEN_PRESS_USER_MODAL,
  OPEN_REFERRAL_INSERT_MODAL,
  OPEN_REPORT_USER_MODAL,
  OPEN_SERVICE_MODAL,
  OPEN_SIGNUP_MODAL,
  OPEN_SOCIAL_SHARE_MODAL,
  OPEN_THANK_YOU_MODAL,
  PHOTO_MODAL_HIDE,
  PHOTO_MODAL_SHOW,
  QUOTE_MODAL_HIDE,
  QUOTE_MODAL_SHOW,
  OPEN_INVITE_EMAIL_MODAL,
  CLOSE_INVITE_EMAIL_MODAL,
  OPEN_LOCAL_SPOT_SHOW_MODAL,
  CLOSE_LOCAL_SPOT_SHOW_MODAL,
} from '../constants';
import history from '../core/history';

import { toggleClose } from './Menu/toggleControl';
import { editBlogPost } from './Social/editBlogPost';

export function openLoginModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_LONGIN_MODAL,
      isLoginModalOpen: true,
      isSignupModalOpen: false,
      isForgotPasswordOpen: false,
    });
    dispatch(toggleClose());
  };
}

export function closeLoginModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_LONGIN_MODAL,
      isLoginModalOpen: false,
    });
    dispatch(toggleClose());
  };
}

export function openSignupModal(initialValues) {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_SIGNUP_MODAL,
      isSignupModalOpen: true,
      isLoginModalOpen: false,
    });
    dispatch(initialize('RegisterForm', initialValues));
  };
}

export function closeSignupModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_SIGNUP_MODAL,
      isSignupModalOpen: false,
    });
  };
}

export function openForgotPasswordModal() {
  return (dispatch, getState) => {
    dispatch(reset('ForgotPasswordForm'));
    dispatch({
      type: OPEN_FORGOT_PASSWORD_MODAL,
      isForgotPasswordOpen: true,
      isLoginModalOpen: false,
    });
  };
}

export function closeForgotPasswordModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_FORGOT_PASSWORD_MODAL,
      isForgotPasswordOpen: false,
    });
  };
}

export function openReportUserModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_REPORT_USER_MODAL,
      payload: {
        isReportUserModalOpen: true,
      },
    });
  };
}

export function closeReportUserModal() {
  return (dispatch, getState) => {
    dispatch(reset('ReportUserForm'));
    dispatch({
      type: CLOSE_REPORT_USER_MODAL,
      payload: {
        isReportUserModalOpen: false,
      },
    });
  };
}

export function openThankYouModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_THANK_YOU_MODAL,
      payload: {
        isThankYouModalOpen: true,
        isReportUserModalOpen: false,
      },
    });
  };
}

export function closeThankYouModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_THANK_YOU_MODAL,
      payload: {
        isThankYouModalOpen: false,
      },
    });
  };
}

export function openReferralModal() {
  return (dispatch, getState) => {
    dispatch(closeSignupModal());
    dispatch(closeLoginModal());
    dispatch({
      type: OPEN_REFERRAL_INSERT_MODAL,
      payload: {
        isReferralInsertOpen: true,
      },
    });
  };
}

export function closeReferralModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_REFERRAL_INSERT_MODAL,
      payload: {
        isReferralInsertOpen: false,
      },
    });
  };
}

export function openNewUserModal() {
  return (dispatch, getState) => {
    dispatch(closeSignupModal());
    dispatch(closeLoginModal());
    dispatch(closeReferralModal());
    dispatch({
      type: OPEN_NEW_USER_INSERT_MODAL,
      payload: {
        isNewUserInsertOpen: true,
      },
    });
  };
}

export function triggerOpenNewUserModal() {
  return (dispatch, getState) => {
    dispatch(closeSignupModal());
    dispatch(closeLoginModal());
    dispatch(closeReferralModal());
    dispatch({
      type: OPEN_NEW_USER_INSERT_MODAL,
      payload: {
        isNewUserInsertOpen: true,
        isNewUserInsertOpenTrigger: true,
      },
    });
  };
}

export function closeNewUserModal() {
  const maxAge = 3600 * 24 * 180;
  cookie.save('cookiesSampleRegister', 'golightly', {
    path: '/',
    maxAge,
  });
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_NEW_USER_INSERT_MODAL,
      payload: {
        isNewUserInsertOpen: false,
      },
    });
  };
}

export function openBookingModal(startDate, endDate) {
  return (dispatch, getState) => {
    dispatch(change('BookingForm', 'startDate', startDate));
    dispatch(change('BookingForm', 'endDate', endDate));
    dispatch({
      type: OPEN_BOOKING_MODAL,
      isBookModalOpen: true,
    });

    // dispatch(toggleClose());
  };
}

export function closeBookingModal() {
  return (dispatch, getState) => {
    dispatch(change('BookingForm', 'startDate', null));
    dispatch(change('BookingForm', 'endDate', null));
    dispatch({
      type: CLOSE_BOOKING_MODAL,
      isBookModalOpen: false,
    });
    // dispatch(toggleClose());
  };
}

export function openPrivateChatModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_MESSAGE_MESSAGE_MODAL,
      payload: {
        isPrivateMessageModal: true,
      },
    });
  };
}

export function closePrivateChatModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_MESSAGE_MESSAGE_MODAL,
      payload: {
        isPrivateMessageModal: false,
      },
    });
  };
}

export function openQuoteModal(reservationId, securityFee) {
  return (dispatch, getState) => {
    dispatch({
      type: QUOTE_MODAL_SHOW,
      payload: {
        quoteModal: true,
        reservationId,
        securityFee,
      },
    });
  };
}

export function closeQuoteModal() {
  return (dispatch, getState) => {
    dispatch({
      type: QUOTE_MODAL_HIDE,
      payload: {
        quoteModal: false,
      },
    });
    dispatch(initialize('QuoteForm', ''));
  };
}

export function openPersonalModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_PERSONAL_MODAL,
      isPersonalModalOpen: true,
      isSignupModalOpen: false,
      isForgotPasswordOpen: false,
    });
    dispatch(toggleClose());
  };
}

export function openSaveCalModal() {
  return (dispatch, getState) => {
    dispatch({
      type: QUOTE_MODAL_SHOW,
      payload: {
        quoteModal: true,
      },
    });
  };
}

export function closePersonalModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_PERSONAL_MODAL,
      isPersonalModalOpen: false,
    });
    dispatch(toggleClose());
  };
}

export function closeSaveCalModal() {
  return (dispatch, getState) => {
    dispatch({
      type: QUOTE_MODAL_HIDE,
      payload: {
        quoteModal: false,
      },
    });
  };
}

export function openEmailModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_EMAIL_MODAL,
      isEmailModalOpen: true,
    });
    dispatch(toggleClose());
  };
}

export function closeEmailModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_EMAIL_MODAL,
      isEmailModalOpen: false,
    });
    dispatch(toggleClose());
  };
}

export function openSocialShareModal() {
  return (dispatch, getState) => {
    const { isAuthenticated } = getState().runtime;

    if (isAuthenticated) {
      dispatch({
        type: OPEN_SOCIAL_SHARE_MODAL,
        payload: {
          isSocialShareModal: true,
        },
      });
    } else {
      history.push('/login');
    }
  };
}

export function closeSocialShareModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_SOCIAL_SHARE_MODAL,
      payload: {
        isSocialShareModal: false,
      },
    });
  };
}

export function openPropertyServiceFeeModal(email, hostPropertyValue) {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_SERVICE_MODAL,
      payload: {
        isserviceModalOpen: true,
      },
    });
    const initial = {
      email,
      hostPropertyValue,
    };
    dispatch(initialize('PropertyServiceFeeModal', initial));
  };
}

export function closePropertyServiceFeeModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_SERVICE_MODAL,
      payload: {
        isserviceModalOpen: false,
      },
    });
  };
}

export function openPressUserModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_PRESS_USER_MODAL,
      payload: {
        isPressUserModal: true,
      },
    });
  };
}

export function closePressUserModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_PRESS_USER_MODAL,
      payload: {
        isPressUserModal: false,
      },
    });
  };
}

export function openMoreFiltersModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_MORE_FILTERS_MODAL,
      payload: {
        isMoreFiltersModal: true,
      },
    });
  };
}

export function closeMoreFiltersModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_MORE_FILTERS_MODAL,
      payload: {
        isMoreFiltersModal: false,
      },
    });
  };
}

export function openPhotoModel(formName, initialData) {
  return (dispatch, getState) => {
    // Initialize the form values
    dispatch(initialize(formName, initialData, true));

    dispatch({
      type: PHOTO_MODAL_SHOW,
      payload: {
        photoModel: true,
      },
    });
  };
}

export function closePhotoModel() {
  return (dispatch, getState) => {
    dispatch({
      type: PHOTO_MODAL_HIDE,
      payload: {
        photoModel: false,
      },
    });
  };
}

export function openConnectionModal(variables) {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_CONNECTION_MODAL,
      isConnectionModalOpen: true,
    });
    dispatch(initialize(variables.formName, variables));
    dispatch(toggleClose());
  };
}

export function closeConnectionModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_CONNECTION_MODAL,
      isConnectionModalOpen: false,
    });
    dispatch(toggleClose());
  };
}

export function openConnectionNoteModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_CONNECTION_NOTE_MODAL,
      isConnectionNoteModalOpen: true,
    });
    // dispatch(initialize('ConnectionSearch', variables));
  };
}

export function closeConnectionNoteModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_CONNECTION_NOTE_MODAL,
      isConnectionNoteModalOpen: false,
    });
  };
}

export function openInputPhoneModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_INPUT_PHONE_MODAL,
      isInputPhoneModalOpen: true,
    });
  };
}

export function closeInputPhoneModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_INPUT_PHONE_MODAL,
      isInputPhoneModalClose: false,
    });
  };
}

export function openHostFullyAgencyModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_HOSTFULLY_AGENCY_MODAL,
      hostFullyModalStatus: true,
    });
  };
}

export function closeHostFullyAgencyModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_HOSTFULLY_AGENCY_MODAL,
      hostFullyModalStatus: false,
    });
  };
}

export function openMyPostModal({ initialValues }) {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_POST_MODAL,
      isMyPostModalOpen: true,
    });
    dispatch(initialize('MyPostBlogContent', initialValues));
  };
}

export function closeMyPostModal(images = []) {
  return (dispatch, getState) => {
    dispatch(reset('MyPostBlogContent'));
    dispatch({
      type: CLOSE_POST_MODAL,
      isMyPostModalOpen: false,
    });
    if (images && images.length > 0)
      axios.post(`${backendUrl}/delete_blog_files`, { files: images });
  };
}

export function openEditPostModal(item, id, refetch, from) {
  return async (dispatch, getState) => {
    await dispatch(editBlogPost(id, item, refetch, from));
  };
}

export function closeEditPostModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_EDIT_POST_MODAL,
      isPostModalOpen: false,
    });
  };
}

export function openPostModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_POST_MODAL,
      isPostModalOpen: true,
    });
    // dispatch(initialize('ConnectionSearch', variables));
  };
}

export function closePostModal() {
  return (dispatch, getState) => {
    dispatch(reset('BlogContent'));
    dispatch({
      type: CLOSE_POST_MODAL,
      isPostModalOpen: false,
    });
  };
}

export const manageSocialBlogShareModal = (id, value) => (dispatch) => {
  dispatch({
    type: MANAGE_SOCIAL_BLOG_SHARE_MODAL,
    isBlogShareModalOpen: value,
    sharingBlogId: id,
  });
};

export const manageSocialLikeModal = (
  isSocialLikeModalOpen,
  blogId,
  likeCount,
  commentLikeData
) => (dispatch) => {
  dispatch({
    type: MANAGE_SOCIAL_LIKE_MODAL,
    isSocialLikeModalOpen,
    blogId,
    likeCount,
    commentLikeData,
  });
};

export function openEmailInviteModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_INVITE_EMAIL_MODAL,
      isEmailInviteOpen: true,
    });
  };
}

export function closeEmailInviteModal() {
  return (dispatch, getState) => {
    dispatch(reset('InviteEmail'));
    dispatch({
      type: CLOSE_INVITE_EMAIL_MODAL,
      isEmailInviteOpen: false,
    });
  };
}

export function openLocalSpotShowModal() {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_LOCAL_SPOT_SHOW_MODAL,
      isLocalSpotShowOpen: true,
    });
  };
}

export function closeLocalSpotShowModal() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_LOCAL_SPOT_SHOW_MODAL,
      isLocalSpotShowOpen: false,
    });
  };
}
