import React from 'react';

import { useJsApiLoader } from '@react-google-maps/api';

import { googleMapAPI } from '../../config';

const libraries = ['places', 'geometry'];

const GoogleMapsLoader = ({ children }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-maps-script',
    googleMapsApiKey: googleMapAPI,
    libraries,
  });

  if (!isLoaded) {
    return null;
  }

  return children;
};

export default GoogleMapsLoader;
