import React from 'react';

import PropTypes from 'prop-types';

import { sitename } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class MemberDetails extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      referDetails: PropTypes.string,
      referAboutUs: PropTypes.string,
      gender: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
  };

  render() {
    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'normal',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };
    const {
      content: {
        email,
        firstName,
        linkedinProfile,
        referDetails,
        referAboutUs,
        gender,
        phoneNumber,
        countryCode,
        dialCode,
      },
    } = this.props;
    const newNumberFormat =
      countryCode === 'US'
        ? phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        : phoneNumber;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />
          <div>An user is interested to be a member in Golightly.</div>
          <EmptySpace height={40} />
          <div>User name: {firstName} </div>
          <EmptySpace height={30} />
          <div>User email: {email} </div>
          <EmptySpace height={30} />
          <div>Gender: {gender} </div>
          <EmptySpace height={30} />
          {referDetails && <div>Referred by: {referDetails} </div>}
          {referDetails && <EmptySpace height={30} />}
          {referAboutUs && (
            <div>How did user get about us: {referAboutUs} </div>
          )}
          {referAboutUs && <EmptySpace height={30} />}
          {linkedinProfile && <div>LinkedIn URL: {linkedinProfile}</div>}
          {linkedinProfile && <EmptySpace height={30} />}
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default MemberDetails;
