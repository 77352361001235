import React from 'react';

import { Table, TBody, TD, TR } from 'oy-vey';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class PropertyUnlist extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      listTitle: PropTypes.string.isRequired,
      hostName: PropTypes.string.isRequired,
    }),
  };

  render() {
    const btnCenter = {
      textAlign: 'center',
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: 'Arial',
      padding: '10px 16px',
      textDecoration: 'none',
      borderRadius: '2px',
      border: '1px solid',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontWeight: 'bold',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      borderColor: '#77d1da',
      backgroundColor: '#77d1da',
      color: '#ffffff',
      borderTopWidth: '1px',
    };

    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const {
      content: { listId, listTitle, hostName, hostProfileId },
    } = this.props;
    const messageURL = `${url}/become-a-host/${listId}/home`;
    const profile = `${url}/users/show/${hostProfileId}`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>
            <Table width="100%">
              <TBody>
                <TR>
                  <TD style={textStyle}>
                    <EmptySpace height={20} />
                    <div>Hi Admin,</div>
                    <EmptySpace height={20} />
                    <div>
                      Host name: <a href={profile}>{hostName}</a>
                    </div>
                    <div>
                      Listing Name: <a href={messageURL}>{listTitle}</a>
                    </div>
                    <EmptySpace height={40} />
                    <div>
                      Thanks, <br />
                      <EmptySpace height={10} />
                      The {sitename} Team
                    </div>
                  </TD>
                </TR>
              </TBody>
            </Table>
            <EmptySpace height={40} />
          </div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default PropertyUnlist;
