import React from 'react';

import cx from 'classnames';
import { PropTypes } from 'prop-types';

import s from './Loader.module.css';

class TextLoader extends React.Component {
  static propTypes = {
    loadingText: PropTypes.string,
  };

  render() {
    const { loadingText, loadingTextSize, searchDots } = this.props;
    return (
      <div className={s.textCenter}>
        <p className={s.saving}>
          <span>{loadingText}</span>
          <span className={cx(s.savingDots, searchDots)}>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </span>
        </p>
      </div>
    );
  }
}

export default TextLoader;
