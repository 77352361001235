import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class BookingPreApprovalAdmin extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      guestName: PropTypes.string.isRequired,
      hostName: PropTypes.string.isRequired,
      threadId: PropTypes.number.isRequired,
      listTitle: PropTypes.number.isRequired,
    }),
  };

  render() {
    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    const linkText = {
      color: '#77d1da',
      fontSize: '18px',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    const {
      content: { receiverName, senderName, listTitle, listId },
    } = this.props;
    const {
      content: { startDate, endDate, receiverProfileId, senderProfileId },
    } = this.props;
    const checkInDate = startDate
      ? moment(startDate).format('ddd, MMM Do, YYYY')
      : '';
    const checkOutDate = endDate
      ? moment(endDate).format('ddd, MMM Do, YYYY')
      : '';
    const listURL = `${url}/rooms/${listId}`;
    const profileURL = `${url}/users/show/`;

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          <div>Hi Admin,</div>
          <EmptySpace height={20} />
          <div>
            <a href={profileURL + receiverProfileId}>{senderName}</a> has
            pre-approved the request by{' '}
            <a href={profileURL + senderProfileId}>{receiverName}</a> for the
            listing{' '}
            <a href={listURL} style={linkText}>
              {listTitle}
            </a>
            . Reservation dates: {checkInDate} {checkOutDate}
          </div>
          <EmptySpace height={30} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default BookingPreApprovalAdmin;
