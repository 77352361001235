import React from 'react';

import cx from 'classnames';
import { PropTypes } from 'prop-types';

import ButtonLoader from './ButtonLoader';
import CircleLoader from './CircleLoader';
import FullPageLoader from './FullPageLoader';
import TextLoader from './TextLoader';

class Loader extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    children: PropTypes.node,
    show: PropTypes.bool,
    loadingText: PropTypes.string,
    label: PropTypes.string,
    buttonType: PropTypes.string,
    handleClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    spinnerColor: PropTypes.string,
  };

  static defaultProps = {
    type: 'text',
    containerClass: null,
  };

  render() {
    const { type, loadingText, children, show, label } = this.props;
    const {
      buttonType,
      handleClick,
      className,
      disabled,
      spinnerColor,
      containerClass,
      searchDots,
    } = this.props;

    return (
      <div className={cx(containerClass, 'videoLoaderHead')}>
        {type === 'text' && (
          <TextLoader loadingText={loadingText} searchDots={searchDots} />
        )}
        {type === 'page' && (
          <FullPageLoader show={show}>{children}</FullPageLoader>
        )}
        {type === 'button' && (
          <ButtonLoader
            className={className}
            disabled={disabled}
            handleClick={handleClick}
            label={label}
            show={show}
            spinnerColor={spinnerColor}
            type={buttonType}
          />
        )}
        {type === 'circle' && <CircleLoader show={show} />}
      </div>
    );
  }
}

export default Loader;
