import React, { Component } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button, Col, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { closeLoginModal, openSignupModal } from '../../actions/modalActions';
import history from '../../core/history';
import messages from '../../locale/messages';
import Link from '../Link';
import LoginForm from '../LoginForm';
import SocialLogin from '../SocialLogin';

import s from './LoginModal.module.css';

class LoginModal extends Component {
  static propTypes = {
    closeLoginModal: PropTypes.func,
    loginModal: PropTypes.bool,
    openSignupModal: PropTypes.func,
    formatMessage: PropTypes.func,
    signUpMsg: PropTypes.bool,
  };

  static defaultProps = {
    isLoading: false,
    isDisabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      loginModalStatus: false,
    };
  }

  componentDidMount() {
    const { loginModal } = this.props;

    if (loginModal === true) {
      this.setState({ loginModalStatus: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loginModal } = nextProps;
    if (loginModal === true) {
      this.setState({ loginModalStatus: true });
    } else {
      this.setState({ loginModalStatus: false });
    }
  }

  redirectBecomeAMember = () => {
    const { closeLoginModal } = this.props;
    history.push('/become-a-member');
    closeLoginModal();
  };

  render() {
    const {
      closeLoginModal,
      openSignupModal,
      signUpMsg,
      isLoading,
      isDisabled,
    } = this.props;
    const { loginModalStatus } = this.state;
    return (
      <div>
        <Modal
          animation={false}
          dialogClassName={cx(s.logInModalContainer, 'loginModal')}
          onHide={closeLoginModal}
          show={loginModalStatus}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage {...messages.login} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={s.logInModalBody}>
            <div className={s.root}>
              <div className={s.container}>
                <p className={cx(s.modalCaptionText)}>
                  <FormattedMessage {...messages.loginInformation} />
                </p>
                <SocialLogin signUpMsg={signUpMsg} />
                <strong className={s.lineThrough}>
                  <FormattedMessage {...messages.or} />
                </strong>
                <LoginForm />
                <hr className={s.horizontalLineThrough} />
                <div className={cx(s.formGroup, s.formSection)}>
                  <Col
                    className={cx(s.noPadding, s.textAlignLeft)}
                    lg={7}
                    md={7}
                    sm={7}
                    xs={12}
                  >
                    {/* <Link
                      className={cx(
                        s.modalCaptionLink,
                        s.modalCaptionLinkLarge
                      )}
                    > */}
                    <span
                      className={cx(
                        s.modalCaptionLink,
                        s.modalCaptionLinkLarge
                      )}
                    >
                      <FormattedMessage {...messages.noAccount} />
                    </span>
                    {/* </Link> */}
                  </Col>
                  <Col
                    className={cx(s.noPadding, s.textAlignRight)}
                    lg={5}
                    md={5}
                    sm={5}
                    xs={12}
                  >
                    <Button
                      bsSize="small"
                      className={cx(s.button, s.btnPrimaryBorder, s.btnSmall)}
                      onClick={this.redirectBecomeAMember}
                    >
                      <FormattedMessage {...messages.signUp} />
                    </Button>
                  </Col>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapState = (state) => ({
  loginModal: state.modalStatus.isLoginModalOpen,
});

const mapDispatch = {
  closeLoginModal,
  openSignupModal,
};

export default connect(mapState, mapDispatch)(LoginModal);
