import React, { useCallback, useEffect, useState } from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'rc-slider/assets/index.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './rentall-common.css';

import * as Sentry from '@sentry/browser';
import detectNearestLocale from 'detect-nearest-locale';
import { createPath } from 'history';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';
import { ApolloProvider } from 'react-apollo';
import cookie from 'react-cookies';
import ReactDOM from 'react-dom';
import MDSpinner from 'react-md-spinner';
import { Provider as ReduxProvider } from 'react-redux';
import UniversalRouter from 'universal-router';

import { getListingAuth } from './actions/Listing/getListingStepTwo';
import { getPropertyServiceFees } from './actions/PropertyServiceFess/getPropertyServiceFees';
import { getServiceFees } from './actions/ServiceFees/getServiceFees';
import { getAllUserNotification } from './actions/Social/notification';
import { openVerifyModal } from './actions/VerificationModal/verifyModal';
import { loadAccount } from './actions/account';
import { getCurrenciesData } from './actions/getCurrencies';
import { getCurrencyRates } from './actions/getCurrencyRates';
import { setRuntimeVariable } from './actions/runtime';
import { setUser } from './actions/setUser';
import { setSiteSettings } from './actions/siteSettings';
import { getAdminUser } from './actions/siteadmin/AdminUser/manageAdminUser';
import App from './components/App';
import { cookieKey, locales, sentryURL } from './config';
import { SET_CURRENT_ROUTE, SET_LOCALE_SUCCESS } from './constants';
import { updateMeta } from './core/DOMUtils';
import createApolloClient from './core/createApolloClient';
import history from './core/history';
import { formatReferURL } from './helpers/formatURL';
import routes from './routes';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import { localeLoaders } from './utils';

Sentry.init({
  dsn: sentryURL,
  debug: process.env.NODE_ENV === 'development',
});

const apolloClient = createApolloClient();

const locale = detectNearestLocale(locales, navigator.language);

const store = configureStore(
  { siteSettings: { data: {} } },
  { history, apolloClient }
);

localeLoaders[locale]().then((translations) => {
  store.dispatch({
    type: SET_LOCALE_SUCCESS,
    payload: {
      locale,
      messages: translations,
    },
  });
});

// Switch off the native scroll restoration behavior and handle it manually
// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
const scrollPositionsHistory = {};
if (window.history && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

const onRenderComplete = (route, location) => {
  document.title = route.title;

  updateMeta('description', route.description);
  // Update necessary tags in <head> at runtime here, ie:
  // updateMeta('keywords', route.keywords);
  // updateCustomMeta('og:url', route.canonicalUrl);
  // updateCustomMeta('og:image', route.imageUrl);
  // updateLink('canonical', route.canonicalUrl);
  // etc.

  let scrollX = 0;
  let scrollY = 0;
  const pos = scrollPositionsHistory[location.key];
  if (pos) {
    scrollX = pos.scrollX;
    scrollY = pos.scrollY;
  } else {
    const targetHash = location.hash.substr(1);
    if (targetHash) {
      const target = document.getElementById(targetHash);
      if (target) {
        scrollY = window.pageYOffset + target.getBoundingClientRect().top;
      }
    }
  }

  // Restore the scroll position if it was saved into the state
  // or scroll to the given #hash anchor
  // or scroll to top of the page
  window.scrollTo(scrollX, scrollY);

  // Google Analytics tracking. Don't send 'pageview' event after
  // the initial rendering, as it was already sent
  if (window.ga) {
    window.ga('send', 'pageview', createPath(location));
  }
};

// Make taps on links and buttons work fast on mobiles
// FastClick.attach(document.body);

const container = document.getElementById('root');
let storeData;
let currentLocation = history.location;

const STEP_COMPLETED = 'completed';

// Re-render the app when window.location changes
async function onLocationChange(location, action) {
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;

  const token = cookie.load(cookieKey);
  let user;

  try {
    user = jwtDecode(token);
    // eslint-disable-next-line no-empty
  } catch (e) {}

  const baseCurrency = cookie.load('currency');

  if (!action) {
    await Promise.all([
      store.dispatch(getCurrencyRates(baseCurrency)),
      store.dispatch(getCurrenciesData()),
      store.dispatch(setSiteSettings()),
      store.dispatch(getServiceFees()),
      store.dispatch(getPropertyServiceFees()),
      store.dispatch(getAllUserNotification()),
    ]);
    // Loading bar
    // store.dispatch(showLoading());
    // Admin Login
    // ("-------request------",req.user.propertyManager);

    store.dispatch(setUser(user));

    if (user?.admin) {
      store.dispatch(
        setRuntimeVariable({
          name: 'isAdminAuthenticated',
          value: true,
        })
      );

      await store.dispatch(getAdminUser(user.id));
      if (user.propertyManager === true) {
        store.dispatch(
          setRuntimeVariable({
            name: 'propertyManager',
            value: true,
          })
        );
        store.dispatch(
          setRuntimeVariable({
            name: 'isCompletedProfile',
            value: true,
          })
        );

        store.dispatch(
          setRuntimeVariable({
            name: 'isProfileUpdated',
            value: true,
          })
        );
        await store.dispatch(loadAccount(null, null, null, true));
      }
    } else if (user) {
      if (user.isPressUser) {
        store.dispatch(
          setRuntimeVariable({
            name: 'isPressUser',
            value: true,
          })
        );

        store.dispatch(
          setRuntimeVariable({
            name: 'pressUserEmail',
            value: user.pressUserEmail,
          })
        );
      }

      if (user.coHost) {
        store.dispatch(
          setRuntimeVariable({
            name: 'isCoHost',
            value: true,
          })
        );
      }

      await store.dispatch(loadAccount(null, null, null, true));
    }

    store.dispatch(
      setRuntimeVariable({
        name: 'initialNow',
        value: Date.now(),
      })
    );

    store.dispatch(
      setRuntimeVariable({
        name: 'availableLocales',
        value: locales,
      })
    );
  }

  storeData = store.getState();
  if (
    currentLocation &&
    currentLocation.pathname &&
    currentLocation.pathname === '/confirm-gender'
  ) {
    if (storeData && storeData.account && storeData.account.data) {
      if (storeData.account.data.gender) {
        history.replace('/notFound');
      }
    }
  }
  if (
    currentLocation &&
    currentLocation.pathname &&
    currentLocation.pathname !== '/confirm-gender' &&
    currentLocation.pathname !== '/unsuccessful' &&
    currentLocation.pathname !== '/confirm-email' &&
    currentLocation.pathname !== '/user/verification' &&
    storeData.runtime.propertyManager !== true
  ) {
    if (storeData && storeData.account && storeData.account.data) {
      if (
        !storeData.account.data.gender &&
        storeData.runtime.propertyManager !== true
      ) {
        history.replace('/confirm-gender');
      } else if (
        storeData.account.data.gender === 'male' &&
        storeData.runtime.propertyManager !== true &&
        !storeData.runtime.isAdminAuthenticated
      ) {
        history.replace('/unsuccessful');
      }
      // if ((storeData.account.data.gender === 'female') && (storeData.account.data.verification && !storeData.account.data.verification.isEmailConfirmed)) {
      //   history.replace('/confirm-email');
      // }
    }
  }

  if (
    currentLocation &&
    currentLocation.pathname &&
    currentLocation.pathname.indexOf('/become-a-host') > -1
  ) {
    const isCurrentLocation =
      currentLocation &&
      currentLocation.pathname &&
      currentLocation.pathname.split('/');
    const isListId =
      isCurrentLocation &&
      isCurrentLocation[2] &&
      !Number.isNaN(isCurrentLocation[2]) &&
      isCurrentLocation[2];
    const userId =
      storeData &&
      storeData.account &&
      storeData.account.data &&
      storeData.account.data.userId;
    if (parseInt(isListId) && userId) {
      const isList = await store.dispatch(getListingAuth(isListId));
      const acccountData = store.getState().account;
      if (isList && isList.status) {
        history.replace('/notFound');
      }
    }
  }

  //  // Prevent Press User Redirecting
  if (
    currentLocation &&
    currentLocation.pathname &&
    currentLocation.pathname === '/users/security'
  ) {
    if (
      storeData &&
      storeData.account &&
      storeData.account.data &&
      storeData.account.data.userId === 'c505e590-f3bc-11e9-bdc7-0913579b3abc'
    ) {
      if (storeData && storeData.runtime && !storeData.runtime.isPressUser) {
        history.replace('/');
      }
    }
  }
  if (storeData && storeData.account && storeData.account.data) {
    if (
      (storeData.account.data.verification &&
        storeData.account.data.verification.isPhoneVerified === false) ||
      (storeData.account.data.userType === 'memberForm' &&
        !storeData.account.data.memberRecords.isVerified) ||
      storeData.account.data.userAccess
    ) {
      store.dispatch(openVerifyModal());
    }
  }

  if (
    currentLocation &&
    currentLocation.pathname &&
    currentLocation.pathname !== '/user/edit' &&
    currentLocation.pathname !== '/confirm-email' &&
    currentLocation.pathname !== '/unsuccessful' &&
    currentLocation.pathname !== '/confirm-gender'
  ) {
    if (storeData && storeData.account && storeData.account.data) {
      if (
        storeData.account.data.verification &&
        storeData.account.data.verification.isPhoneVerified === false
      ) {
        if (
          storeData.account.data.step1 !== STEP_COMPLETED ||
          storeData.account.data.step2 !== STEP_COMPLETED ||
          storeData.account.data.step3 !== STEP_COMPLETED ||
          storeData.account.data.step4 !== STEP_COMPLETED
        ) {
          store.dispatch(openVerifyModal());
        }

        history.replace('/user/edit');
      } else if (
        storeData.account.data.step1 !== STEP_COMPLETED ||
        storeData.account.data.step2 !== STEP_COMPLETED ||
        storeData.account.data.step3 !== STEP_COMPLETED ||
        storeData.account.data.step4 !== STEP_COMPLETED
      ) {
        store.dispatch(openVerifyModal());
        history.replace('/user/edit');
      } else if (
        storeData.account.data.userType === 'memberForm' &&
        !storeData.account.data.memberRecords.isVerified
      ) {
        store.dispatch(openVerifyModal());
        history.replace('/user/edit');
      }
      // if (storeData && storeData.account && storeData.account.data && (storeData.account.data.userType === 'memberForm' && !storeData.account.data.memberRecords.isVerified)) {
      //   store.dispatch(openVerifyModal());
      //   history.replace('/user/edit');
      // }
    }
  }

  if (
    currentLocation &&
    currentLocation.pathname &&
    currentLocation.pathname.includes('/users/show/')
  ) {
    if (
      storeData &&
      storeData.account &&
      storeData.account.data &&
      storeData.runtime &&
      storeData.runtime.isProfileUpdated &&
      !storeData.runtime.isCompletedProfile
    ) {
      if (storeData.account.data.profileId) {
        const splitData = currentLocation.pathname.split('/');
        let param = splitData && splitData[3];
        param = parseInt(param);
        if (param !== storeData.account.data.profileId) {
          history.replace(`/users/show/${storeData.account.data.profileId}`);
        }
      }
    } else if (
      storeData &&
      storeData.runtime &&
      !storeData.runtime.isAuthenticated &&
      !storeData.runtime.isAdminAuthenticated
    ) {
      const splitData = currentLocation.pathname.split('/');
      let param = splitData && splitData[3];
      param = parseInt(param);
      const loc =
        formatReferURL(currentLocation.pathname + currentLocation.search) ||
        null;
      const url = param
        ? `/login?refer=/users/show/${param}`
        : loc
        ? `/login?refer=${loc}`
        : '/login';
      history.push(url);
    }
  }

  if (
    currentLocation &&
    currentLocation.pathname &&
    currentLocation.pathname !== '/user/photo/' &&
    currentLocation.pathname !== '/user/photo' &&
    currentLocation.pathname !== '/user/edit' &&
    !currentLocation.pathname.includes('/users/show/')
  ) {
    if (
      storeData &&
      storeData.account &&
      storeData.account.data &&
      storeData.runtime &&
      storeData.runtime.isProfileUpdated &&
      !storeData.runtime.isCompletedProfile
    ) {
      if (storeData.account.data.profileId) {
        store.dispatch(openVerifyModal());
        history.replace(`/users/show/${storeData.account.data.profileId}`);
      }
    }
  }

  // User Redirect
  if (
    currentLocation &&
    currentLocation.pathname &&
    ['/users/show', '/users/show/'].includes(currentLocation.pathname)
  ) {
    if (storeData && storeData.account && storeData.account.data) {
      if (storeData.account.data.profileId) {
        history.replace(`/users/show/${storeData.account.data.profileId}`);
      }
    }
  }

  if (
    currentLocation &&
    currentLocation.pathname &&
    currentLocation.pathname !== '/user/edit' &&
    currentLocation.pathname !== '/confirm-email' &&
    currentLocation.pathname !== '/unsuccessful' &&
    currentLocation.pathname !== '/confirm-gender'
  ) {
    if (storeData && storeData.account && storeData.account.data) {
      if (
        storeData.account.data.verification &&
        storeData.account.data.verification.isPhoneVerified === false &&
        storeData.account.data.dialCode !== '+971' &&
        storeData.account.data.countryCode !== 'AE'
      ) {
        history.replace('/user/edit');
      }
    }
  }

  if (
    currentLocation &&
    currentLocation.pathname &&
    currentLocation.pathname !== '/user/edit' &&
    currentLocation.pathname !== '/confirm-email' &&
    currentLocation.pathname !== '/unsuccessful' &&
    currentLocation.pathname !== '/confirm-gender'
  ) {
    if (storeData && storeData.account && storeData.account.data) {
      if (
        storeData &&
        storeData.account &&
        storeData.account.data.isProfileUpdated &&
        storeData.account.data.isCompleted &&
        storeData.account.data.userAccess
      ) {
        store.dispatch(openVerifyModal());
        history.replace('/user/edit');
      }
    }
  }

  // Prevent multiple page renders during the routing process
  if (currentLocation.key !== location.key) {
    return;
  }

  // Traverses the list of routes in the order they are defined until
  // it finds the first route that matches provided URL path string
  // and whose action method returns anything other than `undefined`.
  const route = await UniversalRouter.resolve(routes, {
    apolloClient,
    store,
    path: location.pathname,
    query: queryString.parse(location.search),
    locale: store.getState().intl.locale,
  });

  if (route.redirect) {
    history.replace(route.redirect);
    return;
  }

  return route;
}

const Router = () => {
  const [loading, setLoading] = useState(false);
  const [{ route }, setRoute] = useState({ route: null });
  const handleLocationChange = useCallback(async (...args) => {
    setLoading(true);
    const route = await onLocationChange(...args);
    setRoute((state) => ({ route: route || state.route }));
    setLoading(false);
  }, []);

  useEffect(() => {
    history.listen(handleLocationChange);
    handleLocationChange(window.location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (route) {
      onRenderComplete(route, window.location);
      store.dispatch({
        type: SET_CURRENT_ROUTE,
        payload: {
          route: window.location.pathname,
        },
      });
    }
  }, [route]);

  return (
    <>
      {loading && (
        <div
          style={{
            position: 'fixed',
            zIndex: 999,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(255,255,255,0.75)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MDSpinner singleColor="#77d1da" size={48} />
        </div>
      )}
      {route?.component || null}
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ApolloProvider client={apolloClient}>
        <App>
          <Router />
        </App>
      </ApolloProvider>
    </ReduxProvider>
  </React.StrictMode>,
  container
);

serviceWorker.register();
