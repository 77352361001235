import React from 'react';

import { PropTypes } from 'prop-types';
import Loader from 'react-loader-advanced';
import MDSpinner from 'react-md-spinner';

class FullPageLoader extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    show: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    show: false,
  };

  spinner() {
    return <MDSpinner singleColor="#77d1da" size={48} />;
  }

  render() {
    const { children, show } = this.props;

    return (
      <Loader
        backgroundStyle={{ backgroundColor: 'transparent' }}
        foregroundStyle={{ color: 'white' }}
        message={this.spinner()}
        show={show}
      >
        <div>{children}</div>
      </Loader>
    );
  }
}

export default FullPageLoader;
