import {
  EXPLORE_SEARCH_LISTING_RESULTS,
  EXPLORE_SEARCH_LISTING_SUCCESS,
  FETCH_SEARCH_RESULTS_SUCCESS,
  GET_SEARCH_SETTINGS_SUCCESS,
  LOADING_SEARCH_RESULTS,
  MAP_DATA_SUCCESS,
  RESET_LISTING_DATA_SUCCESS,
  SEARCH_LISTING_ERROR,
  SEARCH_LISTING_START,
  SEARCH_LISTING_SUCCESS,
  SEARCH_MAP_LISTING_SUCCESS,
} from '../constants';

export default function search(state = {}, action) {
  switch (action.type) {
    case LOADING_SEARCH_RESULTS:
      return {
        ...state,
        isResultLoading: action.payload.isResultLoading,
        isBarLoading: true,
      };

    case FETCH_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data.results,
        otherResults: action.payload.data.otherResults,
        count: Number(action.payload.data.count),
        industryFilter: action.payload.data.industryFilter,
        industryName: action.payload.data.industryName,
        industryFilterCount: Number(action.payload.data.industryFilterCount),
        isResultLoading: action.payload.isResultLoading,
        isBarLoading: false,
        otherResultsCount: Number(action.payload.data.otherResultsCount),
        worldMapData: Number(action.payload.data.worldMapData),
      };

    case GET_SEARCH_SETTINGS_SUCCESS:
      return {
        ...state,
        searchSettings: action.payload.data,
      };

    case SEARCH_MAP_LISTING_SUCCESS:
      return {
        ...state,
        mapData: action.payload.mapData,
        isLoading: action.payload.isLoading,
        isBarLoading: false,
      };

    case MAP_DATA_SUCCESS:
      return {
        ...state,
        listingData: action.payload.listingData,
      };

    case EXPLORE_SEARCH_LISTING_SUCCESS:
      return {
        ...state,
        data: action.payload.data.results,
        otherResults: action.payload.data.otherResults,
        count: Number(action.payload.data.count),
        industryFilter: action.payload.data.industryFilter,
        industryName: action.payload.data.industryName,
        industryFilterCount: Number(action.payload.data.industryFilterCount),
        isResultLoading: action.payload.isResultLoading,
        isBarLoading: false,
        otherResultsCount: Number(action.payload.data.otherResultsCount),
        isMapLoading: action.payload.isMapLoading,
        // mapData: action.payload.mapData,
        worldMapData: Number(action.payload.data.worldMapData),
      };

    case EXPLORE_SEARCH_LISTING_RESULTS:
      return {
        ...state,
        isMapLoading: action.payload.isMapLoading,
      };

    case RESET_LISTING_DATA_SUCCESS:
      return {
        ...state,
        listingData: action.payload.listingData,
      };
    case SEARCH_LISTING_START: {
      return {
        ...state,
        isSearchLoading: true,
      };
    }
    case SEARCH_LISTING_ERROR:
    case SEARCH_LISTING_SUCCESS: {
      return {
        ...state,
        isSearchLoading: false,
      };
    }

    default:
      return state;
  }
}
