export const restrictionMessage = (values = '') => {
  let emailReturnValue = false;
  let siteAddressExist = false;
  let wordsFind = false;
  let isMailOrPhoneNumberExist = false;
  let isAirbnb = false;
  let mailExist = false;
  let numberExits = false;
  const emailExist =
    values &&
    values
      .replace(/\r/gi, ' ')
      .replace(/\n/gi, ' ')
      .split(' ');
  const listUrlRegex = new RegExp(
    /^(((https?:\/\/|www\.|https?:\/\/www\.)?wegolightly\.com\/rooms\/\d{1,6})(\?startDate=\d{4}-\d{2}-\d{2}&endDate=\d{4}-\d{2}-\d{2})?)$/i
  );

  if (emailExist && emailExist.length > 0) {
    emailExist.forEach((item, index) => {
      const airbnbError = /.*Wegolightly|wegolightly.|wegolightly..*/i.test(
        item.trim()
      );
      const isListUrl = listUrlRegex.test(item.trim());
      const substrings = ['www.', 'https://', 'http://'];
      const isValue = new RegExp(substrings.join('|')).test(item);

      if (/AIRBNB|VRBO/gi.test(item.trim()) && !wordsFind) wordsFind = true;

      if ((/\d{4,}/.test(item.trim()) && !isListUrl) || /(@)/.test(item.trim()))
        isMailOrPhoneNumberExist = true;

      if (
        /^([.! -_%$@~`^&*(){}\n]*)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}([.! -_%$@~`^&*(){}\n]*)$/i.test(
          item.trim()
        ) &&
        !emailReturnValue
      )
        emailReturnValue = true;

      if (
        !(airbnbError || isListUrl) &&
        (isValue ||
          (/(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.(com|org)(\.[a-z]{2,3})?(\/.*)?/i.test(
            item.trim()
          ) &&
            !siteAddressExist))
      ) {
        siteAddressExist = true;
      }
      if (/(@)/.test(item.trim())) mailExist = true;
      if (/\d{4,}/.test(item.trim())) numberExits = true;
    });

    const holeData = values && values.replace(/\s+/g, ' ');

    isAirbnb = /air b|air and B|AIR BNB|AIR B and|AIR AND BNB|a i r b n b|AIR B and BNB|AIR B and BBNB|(air|bnb|(a+)(\s*)(i+)(\s*)(r+)|(b+)(\s*)(n+)(\s*)(b+))[@ |#"&!$%^*{}[\]:;,<>.\\?/()]*(air|bnb|(a+)(\s*)(i+)(\s*)(r+)|(b+)(\s*)(n+)(\s*)(b+))/gi.test(
      holeData.trim()
    );
  }
  return {
    emailReturnValue,
    siteAddressExist,
    wordsFind,
    isAirbnb,
    isMailOrPhoneNumberExist,
    mailExist,
    numberExits,
  };
};

// This below code in SendMessage.js for Future reference

// let result = values && values.content && values.content.replace(/[\s)(]/g, '');
// if ((/\d{4}/.test(result)) || (/(@)/.test(result))) {
//     error = {
//         content: formatMessage(messages.contacthostError3)
//     };
//     sendEmail(adminEmaillist, 'messageAdminError', emailContent);
// } else {
//     const emailExist = values.content.split(' ');
//     let emailReturnValue = false, siteAddressExist = false, wordsFind = false;
//     if (emailExist && emailExist.length > 0) {
//         emailExist.map((item, index) => {
//             if ((/^([.! -_%$@~`%^&*(){}\n]*)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}([.! -_%$@~`%^&*(){}\n]*)$/i.test(item.trim())) && !emailReturnValue) {
//                 emailReturnValue = true;
//             }
//         })
//         emailExist.map((item, index) => {
//             if ((/AIRBNB|VRBO/gi.test(item.trim())) && !wordsFind) {
//                 wordsFind = true;
//             }
//         })
//         emailExist.map((item, index) => {
//             let airbnbError = /.*Wegolightly|wegolightly.|wegolightly..*/i.test(item.trim())
//             let substrings = ["www.", "https://", "http://"]
//             let isValue = new RegExp(substrings.join("|")).test(item);
//             if (!(airbnbError) && (isValue || (/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.(com|org)(\.[a-z]{2,3})?(\/.*)?$/i.test(item.trim()) && !siteAddressExist))) {
//                 siteAddressExist = true;
//             }
//         });
//         let holeData = values && values.content && values.content.replace(/\s+/g, " ");
//         let isAirbnb = ((/air b|air and B|AIR BNB|AIR B and|AIR AND BNB|a i r b n b|AIR B and BNB|AIR B and BBNB|(air|bnb|(a+)(\s*)(i+)(\s*)(r+)|(b+)(\s*)(n+)(\s*)(b+))[\@ | \#| " " | \& | \! | \$ | \% | \^ | \* | \{ | \} | \[ | \] | \: | \; | \" | \, | \< | \> | \. | \\ | \? | \/ | \( | \) ]*(air|bnb|(a+)(\s*)(i+)(\s*)(r+)|(b+)(\s*)(n+)(\s*)(b+))/gi.test(holeData.trim())))
//         if (emailReturnValue || siteAddressExist || wordsFind || isAirbnb) {
//             error = {
//                 content: formatMessage(messages.contacthostError3)
//             };
//             sendEmail(adminEmaillist, 'messageAdminError', emailContent);
//         }
//     }
// }
