import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import { sitename, url } from '../../../config';
import Layout from '../layouts/Layout';
import Body from '../modules/Body';
import EmptySpace from '../modules/EmptySpace';
import Footer from '../modules/Footer';
import Header from '../modules/Header';

class EventConfirmation extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      firstName: PropTypes.string,
      isAdmin: PropTypes.bool,
      eventDescription: PropTypes.string,
      eventEndDate: PropTypes.string,
      eventEndTime: PropTypes.string,
      eventStartDate: PropTypes.string,
      eventStartTime: PropTypes.string,
      eventTimeZone: PropTypes.string,
      eventTitle: PropTypes.string,
      location: PropTypes.string,
    }),
  };

  render() {
    const {
      content: {
        firstName,
        isAdmin,
        eventDescription,
        eventEndDate,
        eventEndTime,
        eventStartDate,
        eventStartTime,
        eventTitle,
        location,
        eventStatus,
        eventTimeZone,
      },
    } = this.props;
    const startTimeMinutes =
      eventStartTime - Math.floor(eventStartTime) === 0.5 ? 30 : 0;
    const endTimeMinutes =
      eventEndTime - Math.floor(eventEndTime) === 0.5 ? 30 : 0;
    const startDate = moment(eventStartDate)
      .set('hours', eventStartTime)
      .set('minutes', startTimeMinutes)
      .set('seconds', 0);
    const endDate = moment(eventEndDate)
      .set('hours', eventEndTime)
      .set('minutes', endTimeMinutes)
      .set('seconds', 0);

    const textStyle = {
      color: '#484848',
      backgroundColor: '#F7F7F7',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '35px',
    };

    return (
      <Layout>
        <Header backgroundColor="#F7F7F7" color="rgb(255, 90, 95)" />
        <Body textStyle={textStyle}>
          {!isAdmin && (
            <div>
              <div>Hi {firstName},</div>
              <EmptySpace height={20} />
              {eventStatus === 'going' ? (
                <div>
                  We have received your Event request successfully and Thank you
                  for your interest.
                </div>
              ) : (
                <div>
                  Thank you for your interest in {eventTitle}. We will contact
                  you if space opens up for this event.{' '}
                </div>
              )}
            </div>
          )}
          {isAdmin && (
            <div>
              <div>Hi Admin,</div>
              <EmptySpace height={20} />
              <div>{firstName} has requested to join the following event.</div>
            </div>
          )}
          <EmptySpace height={20} />
          {(isAdmin || eventStatus === 'going') && (
            <div>
              <div>Event Name: {eventTitle}</div>
              <EmptySpace height={20} />
              <div>
                Event Dates: {eventStartDate} - {eventEndDate}
              </div>
              <EmptySpace height={20} />
              <div>
                Event Time: {startDate.format('LT')} - {endDate.format('LT')}
              </div>
              <EmptySpace height={20} />
              <div>Event Time Zone: {eventTimeZone}</div>
              <EmptySpace height={20} />
              <div>Event Location: {location}</div>
              <EmptySpace height={20} />
              {eventDescription && (
                <div>
                  Event Description: {eventDescription}
                  <EmptySpace height={20} />
                </div>
              )}
            </div>
          )}
          <EmptySpace height={10} />
          <div>Thanks,</div>
          <EmptySpace height={20} />
          <div>The {sitename} Team</div>
        </Body>
        <Footer />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default EventConfirmation;
