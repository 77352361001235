import React from 'react';

import cx from 'classnames';
import { debounce } from 'lodash';
import LazyLoad, { forceCheck } from 'react-lazyload';

import {
  backendUrl,
  cloudFrontUrl,
  golightlyDefault,
  lazyPictureUrl,
} from '../../config';

import s from './LazyLoadImage.module.css';

const debouncedResize = debounce(() => {
  forceCheck();
}, 200);

class LazyLoadImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attempt: 0,
      loaded: false,
    };
  }

  interval;

  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.state.loaded) {
        clearInterval(this.interval);
        return;
      }
      debouncedResize();
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleError = () => {
    if (this.state.attempt < 5) {
      this.setState({ attempt: this.state.attempt + 1 });
    }
  };

  handleLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { src, placeholderSrc } = this.props;
    const { attempt, loaded } = this.state;
    const rentalsUntiedUrl = placeholderSrc
      ?.split(lazyPictureUrl)[1]
      ?.includes('http')
      ? placeholderSrc?.split(lazyPictureUrl)[1]
      : placeholderSrc;
    const srcSet = [
      src,
      `${cloudFrontUrl}${src}`,
      `${backendUrl}${src}`,
      `${backendUrl}${placeholderSrc}`,
      `${cloudFrontUrl}${placeholderSrc}`,
      rentalsUntiedUrl,
      golightlyDefault,
    ];
    const currentSrc = srcSet[attempt];

    return (
      <LazyLoad
        className={cx(
          s.lazyLoadImageContainer,
          loaded ? s.lazyLoadImageLoaded : s.lazyLoadImagePreload
        )}
        height={100}
        offsetVertical={200}
      >
        <img
          onError={this.handleError}
          onLoad={this.handleLoad}
          src={currentSrc}
        />
      </LazyLoad>
    );
  }
}

export default LazyLoadImage;
